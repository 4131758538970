import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiTextArea, UiInput, UiButton, UiSelect, UiDatePicker } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";




const Quote = NiceModal.create(
  ({ data, lid, sts }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      item: yup.array().of(
        yup.object().shape({
          title: yup.string().nullable().required('This is required field'),
          amount: yup.string().nullable().matches(/^[0-9]+$/, "Enter amount").required('This is required field'),
        })
      ),
    });
    const {
      register,
      handleSubmit,
      control,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const insertEntry = () => {

      append()

    }
    const onSubmitHandler = async (data) => {
      SaveData(data);
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#fuform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/z_update_quote",
        data: e,
        // headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
   
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "item", // unique name for your Field Array
    });
    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    const watch_status = watch('item');
    const watch_statusq = watch('item');


    const totalAll = () => {
      console.log(watch_status)
    }
    useEffect(() => {


      if (data) {

        reset(data);
      }


    }, []);

    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Quote" : "New Quote"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="fuform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>


            {fields.map((value, i) => (
              <div className="row">
                <div className="col-md-6" >
                  <UiInput
                    placeholder="Particular"
                    required={true}
                    label="Particular"
                    type="text"
                    className="mb-0"
                    name={`item.[${i}].title`}
                    //parentClass="mb-0"
                    message={errors.item?.[i]?.title?.message}

                    defaultValue={value.title}
                    {...register(`item.[${i}].title`)}
                  />

                </div>

                <div className="flex-grow-1 col-md-4" >
                  <UiInput
                    placeholder="Amount"
                    label="Amount"
                    required={true}
                    type="text"
                    className="mb-0"
                    name={`item.[${i}].amount`}
                    //parentClass="mb-0"
                    message={errors.item?.[i]?.amount?.message}

                    defaultValue={value.amount}
                    {...register(`item.[${i}].amount`, { onBlur: (e) => { totalAll() } })}
                  />

                </div>
                <div className="d-flex align-items-center col-md-2">
                  <a className="btn btn-outline-danger" onClick={() => remove(i)} >X</a>
                </div>
              </div>
            ))}



            <div className="text-center">
              <UiButton type="button" className="btn btn-secondary" onClick={() => insertEntry()} title="Add" icon="add"></UiButton>
            </div>
            <UiInput
              label="Discount"
              required={true}
              type="number"
              className="mb-0"
              name={`discount`}
              //parentClass="mb-0"
              message={errors.discount?.message}

              {...register(`discount`)}
            />
            <input type="hidden" value={lid} name="lead_id" {...register("lead_id")}></input>

            <input type="hidden" name="id" {...register("id")}></input>




          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default Quote;
