import "bootstrap/dist/js/bootstrap.bundle.min.js";
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useStore } from "./state.js";
import { getStorage } from "./helper";
import './styles.scss';
import {
  Routes,
  Route,
  BrowserRouter,
  Link,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import Profile from "./routes/profile";
import Login from "./routes/login";
import TwoFA from "./routes/2fa";

import Layout from "./layout";

import Home from "./routes/home";

import Crop from "./modals/crop";

import Users from "./routes/users";
import User from "./routes/user";
import Update from "./routes/update";
import Updates from "./routes/updates";
import ViewUpdate from "./routes/view_update";

import Clients from "./routes/clients";
import Quotes from "./routes/quotes";
import Client from "./routes/client";
import ViewLead from "./routes/view_lead";
import ViewClient, { Details } from "./routes/view_client";

import ClientLedger from "./routes/view_client/ledger";

import Leads from "./routes/leads";
import ViewQuote from "./routes/view_quote";

import ToDos from "./routes/todos";
import ViewInvoice from "./routes/view_invoice";
import Renew from "./routes/renew";

import NoMatch from "./routes/404";

import AddTodo from "./modals/todo";

import PdfPrint from "./modals/print";

import ChangePasswordProfile from "./modals/change_password";
import Enable2fa from "./modals/enable2fa";
import Disable2fa from "./modals/disable2fa";
import ViewToDo from "./modals/view_todo";
import Followup from "./modals/followup";
import DemoReq from "./modals/demo";
import Quote from "./modals/quote";




import './styles.css';
import NiceModal, { ModalDef, useModal, reducer } from '@ebay/nice-modal-react';
import Lead from "./modals/lead";


if (getStorage('token')) {
  useStore.setState({ auth: 1 });
  //setAuth('1');

}
function AuthRoute({ children }) {
  const auth = useStore((state) => state.auth)
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function ReverseAuthRoute({ children }) {
  const auth = useStore((state) => state.auth)

  if (auth) {
    return <Navigate to="/" replace />;
  }

  return children;
}







export default function App() {

  let navigate = useNavigate();
  useEffect(() => {
    window.addEventListener('hashchange', (event) => {
      //console.log(event.oldURL.split("#")[1])
      var mid = event.oldURL.split("#")[1];
      if (mid) {
        //NiceModal.hide(event.oldURL.split("#")[1])
      }
      if (window.location.hash) {
        //NiceModal.show(window.location.hash.split("#")[1])
      }
    });


  }, []);




  return (
    <>
      <Routes>

        <Route path="/" element={<AuthRoute><Layout /></AuthRoute>}  >
          <Route path="/" element={<AuthRoute><Home /></AuthRoute>} />
          <Route path="profile" element={<AuthRoute><Profile /></AuthRoute>} />

          <Route path="users" element={<AuthRoute><Users /></AuthRoute>} />
          <Route path="user/:id" element={<AuthRoute><User /></AuthRoute>} />
          <Route path="clients" element={<AuthRoute><Clients /></AuthRoute>} />
          <Route path="trials" element={<AuthRoute><Clients /></AuthRoute>} />
          <Route path="leads" element={<AuthRoute><Leads /></AuthRoute>} />
          <Route path="renew-upgrade/:id" element={<AuthRoute><Renew /></AuthRoute>} />

          <Route path="view-lead/:id" element={<AuthRoute><ViewLead /></AuthRoute>} />
          <Route path="view-quote/:id" element={<AuthRoute><ViewQuote /></AuthRoute>} />

          <Route path="client/:id" element={<AuthRoute><Client /></AuthRoute>} />
          <Route path="update/:id" element={<AuthRoute><Update /></AuthRoute>} />

          <Route path="todos" element={<AuthRoute><ToDos /></AuthRoute>} />
          <Route path="quotes" element={<AuthRoute><Quotes /></AuthRoute>} />
          <Route path="updates" element={<AuthRoute><Updates /></AuthRoute>} />


          <Route path="view-update/:id" element={<AuthRoute><ViewUpdate /></AuthRoute>} />
          <Route path="view-invoice/:id" element={<AuthRoute><ViewInvoice /></AuthRoute>} />
          <Route path="view-client/:id" element={<AuthRoute><ViewClient /></AuthRoute>} >
            <Route index element={<AuthRoute><Details /></AuthRoute>} />

            <Route path="ledger" element={<AuthRoute><ClientLedger /></AuthRoute>} />

          </Route>


  


          <Route path="*" element={<AuthRoute><NoMatch /></AuthRoute>} />
        </Route>
        
        <Route path="/login" element={<ReverseAuthRoute><Login /></ReverseAuthRoute>} />

        <Route path="2fa/:id" element={<ReverseAuthRoute><TwoFA /></ReverseAuthRoute>} />


      </Routes>


      <ModalDef id="crop" component={Crop} />
      <ModalDef id="todo" component={AddTodo} />

      <ModalDef id="print" component={PdfPrint} />

      <ModalDef id="change-profile-password" component={ChangePasswordProfile} />
      <ModalDef id="enable-two-step-auth" component={Enable2fa} />
      <ModalDef id="disable-two-step-auth" component={Disable2fa} />

      <ModalDef id="view-todo" component={ViewToDo} />
      <ModalDef id="lead" component={Lead} />
      <ModalDef id="followup" component={Followup} />
      <ModalDef id="demo" component={DemoReq} />
      <ModalDef id="quote" component={Quote} />


    </>
  );
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
