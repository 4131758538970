import create from 'zustand'

export const useBStore = create((set) => ({
  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 }),
}))

export const useStore = create(set => ({
  auth: false,
  clients: { page: 1, count: 1, filter: {} },
  tasks: { page: 1, count: 1, filter: {} },
  filters: {},
  admin: {},
  setClients: (text) =>
    set((state) => ({
      clients: text
    })),
  setTasks: (text) =>
    set((state) => ({
      tasks: text
    })),

  setFilters: (text) =>
    set((state) => ({
      filters: text
    })),
  setAdmin: (text) =>
    set((state) => ({
      admin: text
    })),

  setAuth: (text) =>
    set((state) => ({
      auth: text
    })),
}));