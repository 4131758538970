import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const Disable2fa = NiceModal.create(
  ({ data }) => {
    const [state, setState] = useState({});
    const modal = useModal();

    const schema = yup.object().shape({
      cur_password: yup.string().required("Enter your current password"),
    });
    var disableAuth = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#disableauthpass");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/z_twostep_auth?disable=1",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast('Two Factor App authentication disabled.', { type: "error" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };


    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      disableAuth();
    };



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Disable Two-Factor Authentication</BootstrapModal.Title>
        </BootstrapModal.Header>




        <form id="disableauthpass" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>
            <p className="text-secondary">
              Verify your password to disable two-factor authentication.
            </p>
            <UiInput
            required={true}
              lcol="4"
              icol="8"
              label="Password"
              type="password"
              name="cur_password"
              message={errors.cur_password?.message}
              {...register("cur_password")}
            />


          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Remove" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>





      </BootstrapModal>
    );
  }
);

export default Disable2fa;
