import React, { useState, useRef } from "react";
import { instance } from "../../axios";
//import "bootstrap/dist/css/bootstrap.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UiButton, UiInput } from "../../ui";
import { LoginUser, setStorage } from "../../helper";
import { useStore } from "../../state.js";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logoimg from "../../practive.svg";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import heroImg from "../../img/login.svg"

const Login = ({ ...props }) => {
  const { auth, setAuth } = useStore((state) => state);
  let navigat = useNavigate();
  const recaptchaRef = React.useRef();
  const location = useLocation();

  const schema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });
  const url1 = window.location.href;
  const [state, setState] = useState({});
  var doLogin = (e) => {
    setState({ loader: true });
    var form = document.querySelector("#prloginfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/z_login",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          setAuth('1');
          setStorage("token", response.data.token);
          setStorage("rtoken", response.data.rtoken);

          if (location.state && location.state.from) {
            navigat(location.state.from.pathname, { replace: true });
          } else {
            navigat("/", { replace: true });
          }
        } else if (response.data.status == "2fa") {
          if (location.state && location.state.from) {

            navigat("/2fa/" + response.data.utoken, { state: { from: location.state.from } });
          } else {

            navigat("/2fa/" + response.data.utoken);
          }

        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    doLogin();
    const token1 = recaptchaRef.current.reset();
    //reset();
  };

  return (
    <>
      <Helmet>
        <title>
          Login | Practive
        </title>
      </Helmet>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100 ">
          <div className="col-12  col-lg-8 ">
            <div className="row d-flex justify-content-center align-items-center h-100 rounded shadow-lg">
              <div className="col-12 col-md-6 bg-primary d-none d-md-flex rounded-start" style={{ height: '520px' }}>
                <img className="w-100 p-5" src={heroImg} />
              </div>
              <div className="col-12 col-md-6  bg-white p-4 rounded-end rounded-md-start" style={{ height: '520px' }}>
                <div className="mb-4 text-center">
                  <img className="loginlogo" src={logoimg} />
                </div>
                <div className="card1">
                  <div className="card-body1">
                    <h3 className="mb-5">Sign in</h3>
                    <form id="prloginfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                      <UiInput
                        label="Username"
                        type="text"
                        name="username"
                        message={errors.username?.message}
                        {...register("username")}
                      />
                      <UiInput
                        label="Password"
                        type="password"
                        name="password"
                        message={errors.password?.message}
                        {...register("password")}
                      />

                      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-4">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                            name="logged_in"
                            id="loggedinchk"
                          />
                          <label className="form-check-label" htmlFor="loggedinchk">
                            Keep me logged in
                          </label>
                        </div>
                      </div>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6Ld4ESkhAAAAAC9d3v4sST5JL4eDIP5qqIPtJ6zB"
                      />
                      <UiButton loading={state["loader"]} title="Continue" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
