import React, { useState, useEffect, useRef } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { UiSelect, UiToggle, UiDatePicker, PageError, UiButton, BgLoader, UiInput, InLoader } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DataTable from 'react-data-table-component';
import { format, isValid, parse } from 'date-fns';
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";


export default function ClientLedger() {
  const [pstate, setPSate] = useOutletContext();

  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);
  let componentRef = useRef();


  const schema = yup.object().shape(
    {
      from: yup.string().required('Select date from'),
      to: yup.string().required('Select date to'),

    }
  );


  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    fetchData(data);
  };


  const headers = [
    { label: "Date", key: "date" },
    { label: "Particulars", key: "type" },
    { label: "Debit", key: "debit" },
    { label: "Credit", key: "credit" },
    { label: "Balance", key: "balance" },

  ];
  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      cell: row => (<b className="text-dark">{row.date}</b>),


    },
    {
      name: 'Invoice #',
      selector: row => row.invoice_no,
      cell: row => (<a href={`https://api.practive.in/pdf?voucher=bill&id=${row.id}:${row.token}`} target="_blank">{row.invoice_no}</a>)
    },
    {
      name: 'Particular',
      selector: row => row.item,
      cell: row => (<>{row.item}</>)
    },
    {
      name: 'Payable',
      selector: row => row.payable,
      cell: row => (<>{rupees(row.payable)}</>)
    }
  ];


  const toWatch = watch('to');
  const fromWatch = watch('from');

  let { id1 } = useParams();

  useEffect(() => {
    fetchData(null);
  }, []);



  var fetchData = (e) => {


    showLoader(true)
    instance({
      method: "post",
      url: "/z_ledger?id=" + pstate.client.id,
      data: e,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        showLoader(false)

        setState({ ...state, ...response.data });


      })
      .catch(function (response) { });
  };


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      {state.items ?
        <div className="card card-default">
          {loader && <InLoader />}


          {state.items && state.items.length > 0 ? (
            <>
          
              <div className="card-body pt-0 tablecard">



                <div ref={componentRef}>

                  <DataTable

                    columns={columns}
                    data={state.items}
                  />

                </div>
              </div>

            </>
          ) : state.items && state.items.length == 0 ? (
            <div className="card-body position-relative"><span className="text-muted">No Transactions!</span></div>
          ) : <BgLoader />}
        </div>

        : <BgLoader />}
    </>
  )

}

