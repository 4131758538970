import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, PageError, UiAvatarGroup, UiButton, UiTagDisplay, BgLoader, UiAvatarName, UiTimeDisplay } from "../../ui";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as yup from "yup"; import { NavLink, useNavigate, useParams, Link, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { show, useModal } from "@ebay/nice-modal-react";

import { Helmet } from "react-helmet";
import { rupees } from "../../helper";
import { GoLinkExternal } from "react-icons/go";
import { BsTelephoneOutboundFill, BsWhatsapp } from "react-icons/bs";
import { useQueryClient } from 'react-query';



export function Details() {
  const [pstate, setPState] = useOutletContext();

  const [state, setState] = useState({});

  const BasicDetail = (props) => {
    const { label, val, ...other } = props;
    return (
      <>
        {val &&
          <div className="row mb-3">
            <div className="col-lg-4 text-muted">
              {label}
            </div>
            <div className="col-lg-8" {...other}>

            </div>
          </div>
        }
      </>
    )
  }







  return (
    <>

      <div className="card card-default">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <BasicDetail label="Status" val={pstate.client.status}><span className={`${pstate.client.status == 'Active' ? 'text-success' : 'text-danger'}`}>{pstate.client.status}</span></BasicDetail>
              <BasicDetail label="Plan" val={pstate.client.plan}>{pstate.client.plan}</BasicDetail>
              <BasicDetail label="SMS Balance" val={pstate.client.sms_balance}>{pstate.client.sms_balance}</BasicDetail>
              <BasicDetail label="Expiry" val={pstate.client.expire_on}>{pstate.client.expire_on}</BasicDetail>
              <BasicDetail label="Username (Primary)" val={pstate.client.username}>{pstate.client.username}</BasicDetail>
              <BasicDetail label="URL" val={pstate.client.url}><a href={`https://${pstate.client.url}`} target="_blank">{pstate.client.url}</a></BasicDetail>
       
        

            </div>
            <div className="col-md-6">

              <BasicDetail label="Mobile No." val={pstate.client.mobile}>
                <div className="d-flex align-items-center" >
                  <span>{pstate.client.mobile}</span>
                  <OverlayTrigger overlay={<Tooltip >Make Call</Tooltip>}>
                    <a href={`tel:${pstate.client.mobile}`} target="_blank" className="ms-3"><span className="sicon"><BsTelephoneOutboundFill /></span></a>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip >Send WhatsApp</Tooltip>}>
                    <a className="ms-2 text-success" href={`https://wa.me/${pstate.client.mobile}`} target="_blank"><span className="sicon"><BsWhatsapp /></span></a>
                  </OverlayTrigger>

                </div>
              </BasicDetail>


   

              <BasicDetail label="Email" val={pstate.client.email}><div className="d-flex align-items-center" ><span>{pstate.client.email}</span>
                <OverlayTrigger overlay={<Tooltip >Send Email</Tooltip>}>
                  <a href={`mailto:${pstate.client.email}`} className="ms-3" target="_blank"><span className="sicon"><GoLinkExternal /></span></a>
                </OverlayTrigger>
              </div></BasicDetail>

              <BasicDetail label="Address" val={pstate.client.address}>{pstate.client.address}</BasicDetail>
              <BasicDetail label="City" val={pstate.client.city}>{pstate.client.city}</BasicDetail>
              <BasicDetail label="Pincode" val={pstate.client.pincode}>{pstate.client.pincode}</BasicDetail>
              <BasicDetail label="State" val={pstate.client.state}>{pstate.client.state}</BasicDetail>

            </div>
          </div>


        </div>
      </div>
 
    </>
  )

}

export default function ViewClient() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);



  const fetchData = () => {
    instance
      .get("/z_client_dashboard?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });



      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        {state.client && (
          <title>
            {state.client.name} | Clients | Practive
          </title>
        )}
      </Helmet>
      <div>
        {state.client ? (
          <div>
            <PageHeader title="Client Dashboard" parent="Clients" link="/clients">
          <Link to={`/renew-upgrade/${id}`} className="btn btn-primary">Renew/Upgrade</Link>
             
            </PageHeader>





            <div className="row">
              <div className="col-md-3">
                <div className="card card-default mb-4">
                  <div className="card-body">
                    {state.client.photo && (
                      <div className="border rounded p-2 w-100">
                        <img className="w-100" src={state.client.photo} />
                      </div>
                    )}
                    <h3 className="fs-4 my-3">{state.client.name}</h3>
                    <h4 className="fs-5 my-3">{state.client.company}</h4>
                   
                  </div>
                </div>

              </div>
              <div className="col-md-9">
                <div className="tabs mb-3">
                  <NavLink to="" end className="tab-itm">Details</NavLink>

                  <NavLink to="ledger" className="tab-itm">Ledger</NavLink>
            
                </div>

                <Outlet context={[state, setState]} />

              </div>
            </div>


          </div>
        ) : <BgLoader />}


      </div>
    </>
  );
}
