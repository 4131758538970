import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, UiInput, UiButton, UiTextArea, UiDatePicker, UiRSelect, UiImageUpload, UiToggle, BgLoader, PageHeader, PageError } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import chroma from 'chroma-js';
import Select, { StylesConfig } from 'react-select';
import { Helmet } from "react-helmet";
import { format, isValid, parse } from 'date-fns';
import { rupees } from '../../helper'


export default function Renew() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [photo, setPhoto] = useState(null);
  const [slabs, setSlabs] = useState([]);
  const [price, setPrice] = useState();


  const location = useLocation();
  const schema = yup.object().shape({

    users: yup.string().required("Enter users"),
    duration: yup.string().required("Select duration"),




  }, []);


  var handleSave = (e) => {
    setState({ ...state, loader: true });

    var form = document.querySelector("#signupfrm");
    var data = new FormData(form);
    instance({
      method: "post",
      url: "/z_init_transaction",
      data: data,
      // headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['clients'])
          toast(response.data.msg, { type: "success" });

          navigate(`/view-client/${id}`)



        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    unregister,
    getValues,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };








  const fetchData = () => {
    instance
      .get("/z_add_client?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        setSlabs(response.data.slabs)
        if (response.data.status == 'success') {

        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }







  useEffect(() => {
    fetchData();

  }, [location]);





  const watchduration = watch('duration');
  const w_discount = watch('discount');
  const w_last_discount = watch('last_discount');
  const users = watch('users');


  const processTotal = () => {
    var year = parseInt(watchduration) / 12;
    console.log(price)
    var subtotal = (price * parseInt(users)) * year;
    var discount = 0;
    var last_discount = 0;

    if (w_discount > 0) {
      discount = parseInt(w_discount);
    }
    if (w_last_discount > 0) {
      last_discount = parseInt(w_last_discount);
    }
    var subtotal1 = subtotal - (discount + last_discount);
    var tax = Math.round(subtotal1 * 18 / 100);
    var amount = subtotal1 + tax;
    setState({ ...state, amount: amount, tax: tax, subtotal: subtotal, discount: discount, last_discount: w_last_discount })
  }

  useEffect(() => {
   processTotal()
  }, [price]);






  useEffect(() => {
    if (slabs) {
      
      var users1 = parseInt(users)
      const prices = slabs
      let shouldSkip = false;

      prices.forEach(element => {
        if (shouldSkip) {
          return;
        }
        if (element.users >= users1) {
          //console.log(element)
          setPrice(element.price)
          //processTotal()
          shouldSkip = true;
          return;
        }
      });
    }
  }, [users, slabs]);



  useEffect(() => {
    if (watchduration) {

      processTotal()
    }
  }, [watchduration]);

  useEffect(() => {
    if (w_discount) {

      processTotal()
    }
  }, [w_discount]);



  useEffect(() => {
    if (w_last_discount) {

     processTotal()
    }
  }, [w_last_discount]);







  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }



  return (
    <>
      <Helmet>
        <title>
          Renew/Upgrade | Practive
        </title>
      </Helmet>
      <div>
        {state.states ? (
          <div>
            <PageHeader title='Renew/Upgrade' parent="Client" link={`/view-client/${id}`}>

            </PageHeader>



            <form id="signupfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

              <div className="row">
                <div className="col-lg-6">


                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Client
                    </div>
                    <div className="card-body">

                      <b>{state.client.name}</b>



                    </div>
                  </div>

                </div>
                <div className="col-lg-6">

                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Plan
                    </div>
                    <div className="card-body">
                    <UiInput
                        label="Users"
                        required={true}
                        type="number"
                        name="users"
                        message={errors.users?.message}
                        {...register("users")}
                      />

                      <UiSelect
                        label="Duration (Months)"
                        name="duration"
                        noBlank={true}
                        required={true}
                        options={state.duration}
                        message={errors.duration?.message}
                        {...register("duration")}
                      />

                      <input type="hidden" name="client" value={id}></input>
               

                      <UiInput
                        label="Previous Plan Discount"
                        type="number"
                        name="last_discount"
                        message={errors.last_discount?.message}
                        {...register("last_discount")}
                      />
                      <UiInput
                        label="Discount"
                        type="number"
                        name="discount"
                        message={errors.discount?.message}
                        {...register("discount")}
                      />

                      <div className="mb-4 border-bottom pb-4">
                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Plan Price</span>
                          <span>{rupees(state.subtotal)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Previous Plan Discount</span>
                          <span>-{rupees(state.last_discount)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Discount</span>
                          <span>-{rupees(state.discount)}</span>
                        </div>


                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Tax ({state.tax_percent}%)</span>
                          <span>{rupees(state.tax)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2 fw-bold">
                          <span className="text-secondary">Final Amount</span>
                          <span>{rupees(state.amount)}</span>
                        </div>
                      </div>


                    </div>
                  </div>

                </div>
              </div>








              <UiButton loading={state["loader"]} title="Save" />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}
