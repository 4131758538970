import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, InLoader, UiAvatarGroup, UiActioButton, UiActionDropDown, UiInput, UiSelect, Avatar, PageError, Toastcontent } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { AiOutlineMore } from 'react-icons/ai'
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { BsTelephoneOutboundFill, BsWhatsapp } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { rupees } from '../../helper'
import { DropdownItem } from '@atlaskit/dropdown-menu';



export default function Updates() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);


  const schema = yup.object().shape(
    {

    }
  );
  const Dtcheckbox = forwardRef(({ ...props }, ref) => (
    <input ref={ref} className="form-check-input" type="checkbox" {...props} />
  ));

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { isDirty } = formState;


  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/updates?" + u, { replace: true })
    } else {
      navigate("/updates?" + u)
    }


  };





  const headers = [
    { label: "Type", key: "type" },
    { label: "Title", key: "title" },
    { label: "Date", key: "date" },
    { label: "Positive", key: "positive" },
    { label: "Negative", key: "negative" },
    { label: "Neutral", key: "neutral" },


  ];

  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});

  const handleRowSelected = React.useCallback(state => {
    setState1({ selected: state.selectedRows })
  }, []);


  async function fetchData(filter) {

    const { data } = await instance.get(
      "/z_updates" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    type: null,
    tag: null,
    users: null,
    service: null,
    group: null,
    status: '',
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/updates", { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);



  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [

    {
      name: 'Date',
      selector: row => row.date,
      cell: row => (<span className="text-truncate" title={row.date}>{row.date}</span>)
    },
    {
      name: 'Title',
      selector: row => row.title,
      cell: row => (<Link to={`/view-update/${row.id}`}><b>{row.title}</b></Link>),

      id: 'title',
      width: '300px'
    },
    {
      name: 'Positive',
      selector: row => row.positive,
      cell: row => (<span className="text-success" >{row.positive}</span>)
    },
    {
      name: 'Negative',
      selector: row => row.negative,
      cell: row => (<span className="text-danger" >{row.negative}</span>)
    },
    {
      name: 'Neutral',
      selector: row => row.neutral,
      cell: row => (<span className="text-warning" >{row.neutral}</span>)
    }





  ];

  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/updates?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/updates?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/updates?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/updates?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["updates", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });

  const clearSelection = () => {
    setToggleCleared(!toggleCleared)
    setState1({ selected: [] })
  }
  useEffect(() => {


    const defaultValues1 = {
      search: filter.search,
      status: filter.status,

    };
    reset(defaultValues1)

  }, [filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Updates | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Updates" >
            {data.items &&
              <CSVLink data={data.items} filename={"Updates.csv"} headers={headers} className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`} >Export</CSVLink>
            }

            <UiButton className="btn btn-primary" onClick={() => navigate("/update/new")} title="New" icon="add" ></UiButton>


          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

            <div className="p-3 bg-light rounded mb-4 ">

              <div className="row">

                <div className="col">
                  <UiInput
                    label="Search"
                    type="text"

                    name="search"

                    {...register("search")}
                  />
                </div>












                <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>

                </div>





                <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                  <div className="d-flex justify-content-end">
                    {zstate.more_filter == true ? (
                      <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                    ) : (

                      <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                    )}

                    <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                    <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                  </div>

                </div>
              </div>



            </div>

          </form>

          {state1.selected && state1.selected.length > 0 && (
            <div className="bulkaction">

            </div>
          )}
          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body tablecard">

              <DataTable
                columns={columns}
                pagination
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                clearSelectedRows={toggleCleared}
                onSort={handleSort}
                sortServer
                persistTableHead
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="Updates" />}
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
