import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, UiInput, UiButton, UiTextArea, UiDatePicker, UiRSelect, UiImageUpload, UiToggle, BgLoader, PageHeader, PageError } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import chroma from 'chroma-js';
import Select, { StylesConfig } from 'react-select';
import { Helmet } from "react-helmet";
import { format, isValid, parse } from 'date-fns';
import { rupees } from '../../helper'


export default function Client() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [photo, setPhoto] = useState(null);
  const [slabs, setSlabs] = useState([]);
  const [price, setPrice] = useState();

  const location = useLocation();
  const schema = yup.object().shape({

    name: yup.string().required("Client Name is required").max(50, 'Maximum character limit is 50'),
    company: yup.string().required("Enter company"),
    users: yup.string().required("Enter users"),
    duration: yup.string().required("Select duration"),
    address: yup.string().max(160, 'Maximum character limit is 160'),

    mobile: yup.string().matches(/^[0-9]+$/, "Enter 10 digit mobile number")
      .min(10, "Enter 10 digit mobile number")
      .max(10, "Enter 10 digit mobile number"),


    email: yup.string().email("Enter valid email address").required('Enter email'),


    state: yup.string().required("Select state"),

    gst: yup
      .string()
      .nullable()
      .when("gst", {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .matches(
              /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
              "Enter valid GST number"
            )
            .required("Enter GST number"),
      }),





  }, [["mobile", "mobile"], ["email", "email"], ["gst", "gst"]]);


  var handleSave = (e) => {
    setState({ ...state, loader: true });

    var form = document.querySelector("#signupfrm");
    var data = new FormData(form);
    instance({
      method: "post",
      url: "/z_init_signup",
      data: data,
      // headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['clients'])
          toast(response.data.msg, { type: "success" });

          navigate("/clients/")



        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    unregister,
    getValues,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };


  const defaultValues = {
    photo: '',
    status: '',
    name: '',
    type: '',
    mobile: '',
    mobile_2: '',
    email: '',
    pan: '',
    gst: '',
    file: '',
    group: '',
    address: '',
    city: '',
    pincode: '',
    state: '',
    users: '',
    tags: '',
    opening_balance: '',
    contact_person: '',
    dob: null,
    cfr: null,
    cf: null,

  };

  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (data.color == undefined || data.color == null) {
        data.color = '#000000';
      }
      const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      if (data.color == undefined || data.color == null) {
        data.color = '#000000';
      }
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };


  const watchduration = watch('duration');
  const w_discount = watch('discount');
  const users = watch('users');
  const is_trial = watch('trial');


  const processTotal = () => {
    var year = parseInt(watchduration) / 12;
    console.log(price)
    var subtotal = (price * parseInt(users)) * year;
    var discount = 0;

    if (w_discount > 0) {
      discount = parseInt(w_discount);
    }
    var subtotal1 = subtotal - discount;
    var tax = Math.round(subtotal1 * 18 / 100);
    var amount = subtotal1 + tax;
    setState({ ...state, amount: amount, tax: tax, subtotal: subtotal, discount: discount })
  }




  const fetchData = () => {
    instance
      .get("/z_add_client")
      .then(function (response) {
        setState({ ...state, ...response.data });
        setSlabs(response.data.slabs)
        if (response.data.status == 'success') {

        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }







  useEffect(() => {
    fetchData();

  }, [location]);


  useEffect(() => {
    return () => {
      reset(defaultValues)
    }
  }, [location]);

  useEffect(() => {
    processTotal()
  }, [price]);






  useEffect(() => {
    if (slabs) {

      var users1 = parseInt(users)
      const prices = slabs
      let shouldSkip = false;

      prices.forEach(element => {
        if (shouldSkip) {
          return;
        }
        if (element.users >= users1) {
          //console.log(element)
          setPrice(element.price)
          //processTotal()
          shouldSkip = true;
          return;
        }
      });
    }
  }, [users, slabs]);



  useEffect(() => {
    if (watchduration) {

      processTotal()
    }
  }, [watchduration]);

  useEffect(() => {
    if (w_discount) {

      processTotal()
    }
  }, [w_discount]);







  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }



  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Client | Practive
        </title>
      </Helmet>
      <div>
        {state.states ? (
          <div>
            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Client`} parent="Clients" link="/clients">

            </PageHeader>



            <form id="signupfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

              <div className="row">
                <div className="col-lg-6">


                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Basic Details
                    </div>
                    <div className="card-body">


                      <div className="row">
                        <div className="col-sm-6">
                          <UiInput
                            label="Client Name"
                            type="text"
                            required={true}
                            name="name"
                            message={errors.name?.message}
                            {...register("name")}
                          />
                        </div>
                        <div className="col-sm-6">
                          <UiInput
                            label="Company"
                            type="text"
                            required={true}
                            name="company"
                            message={errors.company?.message}
                            {...register("company")}
                          />

                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <UiInput
                            label="Mobile No."
                            type="text"
                            required={true}
                            name="mobile"
                            message={errors.mobile?.message}
                            {...register("mobile")}
                          />
                        </div>
                        <div className="col-sm-6">
                          <UiInput
                            label="Email"
                            required={true}
                            type="email"
                            name="email"
                            message={errors.email?.message}
                            {...register("email")}
                          />

                        </div>

                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <UiSelect
                            label="State"
                            name="state"
                            required={true}
                            options={state.states}
                            message={errors.state?.message}
                            {...register("state")}
                          />
                        </div>
                        <div className="col-sm-6">
                          <UiInput
                            label="GSTIN"
                            type="text"
                            name="gst"
                            message={errors.gst?.message}
                            {...register("gst")}
                          />
                        </div>


                      </div>


                      <UiTextArea
                        label="Address"
                        name="address"
                        message={errors.address?.message}
                        {...register("address")}
                      />



                    </div>
                  </div>

                </div>
                <div className="col-lg-6">

                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Plan
                    </div>
                    <div className="card-body">
                      <UiToggle
                        id="trial"
                        name="trial"
                        title="Create Trial Account"
                        {...register("trial")}
                      />
                      <UiInput
                        label="Users"
                        required={true}
                        type="number"
                        name="users"
                        message={errors.users?.message}
                        {...register("users")}
                      />

{is_trial == 1 ? <>
  <UiInput
                        label="Days"
                        required={true}
                        type="number"
                        name="duration"
                        message={errors.duration?.message}
                        {...register("duration")}
                      />
</>
: <>
                      <UiSelect
                        label="Duration (Months)"
                        name="duration"
                        noBlank={true}
                        required={true}
                        options={state.duration}
                        message={errors.duration?.message}
                        {...register("duration")}
                      />



                      <UiInput
                        label="Discount"
                        type="number"
                        name="discount"
                        message={errors.discount?.message}
                        {...register("discount")}
                      />

                      <div className="mb-4 border-bottom pb-4">
                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Plan Price</span>
                          <span>{rupees(state.subtotal)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Discount</span>
                          <span>-{rupees(state.discount)}</span>
                        </div>


                        <div className="d-flex justify-content-between mb-2">
                          <span className="text-secondary">Tax ({state.tax_percent}%)</span>
                          <span>{rupees(state.tax)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2 fw-bold">
                          <span className="text-secondary">Final Amount</span>
                          <span>{rupees(state.amount)}</span>
                        </div>
                      </div>
                      </>
}

                    </div>
                  </div>

                </div>
              </div>








              <UiButton loading={state["loader"]} title="Save" />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}
