import React, { useState, useEffect } from "react";

import { useInView } from 'react-intersection-observer';

import { useNavigate, Link, useOutletContext } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Helmet } from "react-helmet";
import { instance } from "../../axios";
import { BgLoader, InLoader, PageHeader, TableZero, UiActioButton, UiDateRange, UiSelect, Avatar } from "../../ui";
import { useStore } from "../../state.js";
import { MdOutlinePending, MdOutlineAccessTime, MdOutlinePauseCircle, MdCheckCircleOutline } from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CountUp from 'react-countup';
import 'chartjs-adapter-date-fns'
import avgtimeImg from '../../avg_time.svg'
import taskBG from '../../task.svg'
import billBG from '../../bill.svg'
import { ToDoList } from './todo_list.js'

import {
  Chart as ChartJS, ArcElement, CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  LineElement,
  Title, Tooltip as Tooltip1, Legend
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';







export default function Home() {
  ChartJS.register(CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip1, Legend);
  const [astate, setAState] = useOutletContext();
  const admin_state = useStore((state) => state.admin);
  const [state, setState] = useState({});
  const [period, setPeriod] = useState('This Month');
  const [view, setView] = useState(astate.is_admin == 1 ? 'Admin' : 'User');

  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 1,
  });




  const changePeriod = (e) => {
    setPeriod(e)

  }


  const fetchData = () => {
    setLoader(true)
    instance
      .get("/z_home?period=" + period)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }

  useEffect(() => {

    fetchData()

  }, [period]);

  const client_donut_opt = {

    rotation: -90,
    circumference: 180,

  }



  return (
    <>
      <Helmet>
        <title>
          Dashboard | Practive
        </title>
      </Helmet>
      <div>

        <PageHeader title="Dashboard" stacked={true}>
          <div className="d-flex align-items-center" style={{ maxWidth: '450px' }}>
            {admin_state.is_admin && admin_state.is_admin == 1 &&
              <>
                <span className="me-2 d-none d-lg-block">View as</span>
                <UiSelect parentClass="mb-0 me-3" value={view} onChange={(e) => setView(e.target.value)} noBlank={true} name="view" options={['Admin', 'User']} />
              </>
            }
            <div className="flex-grow-1" style={{ minWidth: '230px' }}>

              <UiDateRange
                parentClass="mb-0"
                onUpdate={(e) => changePeriod(e)}
                value={period}
                //ref={ref}
                name='date'

              />
            </div>
          </div>
        </PageHeader>

        <div className="row">
          <div className="col-md-4  mb-4">
            <ToDoList />

          </div>



          <div className="col-md-5 mb-4">
            <TasksOverview items={state.sources} loader={loader} period={period} />
          </div>
          <div className="col-md-3  mb-4">
            <div className="mb-4 c-pointer" style={{ height: '173px' }} onClick={() => navigate(`/leads?status=Open&date=${period}`)}>
              <div className="card text-white" style={{ backgroundColor: '#F1416C' }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <span className="fs-1 fw-bolder"><CountUp end={state.open} /></span>
                    <img style={{ width: '38px', opacity: '0.2' }} src={taskBG} />
                  </div>

                  <p className="text-white text-opacity-75 text-truncate">Open leads</p>
                  <p className="text-sm text-white text-opacity-75 mb-1">{state.completed} Completed</p>
                  <div className="progress bg-white bg-opacity-50" style={{ height: '10px' }} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar bg-white" style={{ width: (state.completed ? state.completed : '0%') }}></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4 c-pointer" onClick={() => navigate(`/leads?followup_date=Today`)}>
              <div className="card text-white" style={{ backgroundColor: '#2ecc71' }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <span className="fs-1 fw-bolder"><CountUp end={state.followup} /></span>
                    <img style={{ width: '38px', opacity: '0.2' }} src={taskBG} />
                  </div>

                  <p className="text-white text-opacity-75 text-truncate">Follow-up today</p>

                </div>
              </div>
            </div>

            <div className="mb-4 c-pointer" style={{ height: '173px' }} onClick={() => navigate(`/leads?demo_date=Today`)}>
              <div className="card text-white" style={{ backgroundColor: '#3498db' }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <span className="fs-1 fw-bolder"><CountUp end={state.demo} /></span>
                    <img style={{ width: '38px', opacity: '0.2' }} src={taskBG} />
                  </div>

                  <p className="text-white text-opacity-75 text-truncate">Demo today</p>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {admin_state.user.role == 1 &&
            <div className="col-md-3 mb-4">
              <div className="mb-4" style={{ height: '173px' }}>
                <div className="card text-white" style={{ backgroundColor: '#F1416C' }}>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <span className="fs-1 fw-bolder"><CountUp end={state.new_clients} /></span>
                      <img style={{ width: '38px', opacity: '0.2' }} src={taskBG} />
                    </div>

                    <p className="text-white text-opacity-75 text-truncate">New Clients</p>

                  </div>
                </div>
              </div>
            </div>
          }
          <div className="col-md-9 mb-4">
            <TaskLineChart period={period} />
          </div>
        </div>



      </div>
    </>
  );
}


export function TasksOverview(props) {
  const { loader, items, period } = props;
  let navigate = useNavigate();






  return (
    <div className="card card-default">
      <div className="card-header"><div className="d-flex justify-content-between">
        <span>Leads</span>

      </div></div>
      <SimpleBar style={{ height: 496 }}>
        <div className="position-relative" >
          {loader == true && <InLoader />}
          <div className="card-body pt-3">
            {items && items.length > 0 ?
              <>
                <div className="row mb-2">
                  <div className="col-6 text-muted fw-semibold text-uppercase">
                    Source
                  </div>
                  <div className="col-2">
                    <OverlayTrigger overlay={<Tooltip >Open</Tooltip>} >
                      <span className="fs-4 text-warning justify-content-center sicon"> <MdOutlinePending /></span>
                    </OverlayTrigger>

                  </div>

                  <div className="col-2">
                    <OverlayTrigger overlay={<Tooltip >Closed</Tooltip>} >
                      <span className="fs-4 text-primary justify-content-center sicon"> <MdOutlineAccessTime /></span>
                    </OverlayTrigger>
                  </div>
                  <div className="col-2">
                    <OverlayTrigger overlay={<Tooltip >Converted</Tooltip>} >
                      <span className="fs-4 text-success justify-content-center sicon"><MdCheckCircleOutline /></span>
                    </OverlayTrigger>
                  </div>
                </div>

                {items.map((val, i) => (
                  <div className="row border-top py-2" key={i}>
                    <div className="col-6">
                      <p className="text-truncate text-start mb-0 " title={val.source}>{val.source}</p>
                    </div>
                    <div className="col-2 text-center"><Link to={`/leads?source=${val.source}&status=Open&date=${period}`}>{val.open}</Link></div>
                    <div className="col-2 text-center"><Link to={`/leads?source=${val.source}&status=Closed&date=${period}`}>{val.closed}</Link></div>
                    <div className="col-2 text-center"><Link to={`/leads?source=${val.source}&status=Converted&date=${period}`}>{val.converted}</Link></div>
                  </div>

                ))}
              </>
              : items && items.length == 0 && <TableZero title="Leads" />}

          </div>
        </div>
      </SimpleBar>
    </div>
  )
}


export function TaskLineChart(props) {
  const { period, view } = props
  let navigate = useNavigate();

  ChartJS.register(CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip1, Legend);

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        grid: {
          display: false,
        },
        time: {
          unit: 'day',
        },
      },

    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false,

      },
    },
  };
  const [optns, setOptns] = useState(options);

  const fetchData = () => {
    setLoader(true)
    instance
      .get("/z_lead_performance?period=" + period)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });

        if (response.data.type) {

          var arr = options;
          arr.scales.x.time.unit = response.data.type;
          setOptns(arr)
        }

      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period, view]);





  return (
    <div className="card card-default" style={{ height: '550px' }}>
      <div className="card-header"><div className="d-flex justify-content-between">
        <span>Performance</span>
      </div></div>

      {loader == true && <InLoader />}
      <div className="card-body">
        {state.items &&
          <Line options={optns} data={state.items} />}


      </div>
    </div>
  )

}