import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiInput, UiButton, PageHeader, BgLoader, OverlayLoader, UiImageUpload, PageError } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import { Helmet } from "react-helmet";


export default function User() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [bg_loader, setBGLoader] = useState(false);
  const [loader, showLoader] = useState(false);


  const schema = yup.object().shape({
    name: yup.string().required("Enter user's full name").max(30,'Maximum character limit is 30'),
    username: yup.string().required("Enter login username").max(30,'Maximum character limit is 30'),
    role: yup.string().required("Select role"),
    status: yup.bool(),
    email: yup.string().nullable().when('email', {
      is: (value) => value?.length,
      then: (rule) => rule.email("Enter valid email address")

    }),
    mobile: yup.string().nullable().when('mobile', {
      is: (value) => value?.length,
      then: (rule) => rule.matches(/^[0-9]+$/, "Enter 10 digit mobile number")
        .min(10, "Enter 10 digit mobile number")
        .max(10, "Enter 10 digit mobile number"),
    }),


    password: yup.string().when('change_password', {
      is: true,
      then: yup.string().required("Please enter password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
    }),
    password1: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "The password and its confirm are not the same"
      ),
  }, [["mobile", "mobile"], ["email", "email"]]);



  var handleSave = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#userfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/z_update_user",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          navigate(-1)
          queryClient.invalidateQueries(['users'])
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    control,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave();
  };

  const watchPass = watch("change_password");

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "It will delete all Attendance entries and To-Dos of this user.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/z_update_user?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['users'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    instance
      .get("/z_get_update_user?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {
         
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }

  const removeTwoStep = (idd) => {
    setBGLoader(true)
    instance
      .get("/z_remove_user_2fa?id=" + idd)
      .then(function (response) {
        setBGLoader(false)
        if (response.data.status == 'success') {
          toast(response.data.msg, { type: "success" });
          setState({ ...state, user: { ...state.user, twostep: false } });
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    fetchData();
    if (id == 'new') {
      reset({ change_password: true, status:true })
    }
  }, []);

  useEffect(() => {
    reset(state.user)
  }, [state.user]);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} User | Practive
        </title>
      </Helmet>
      <div>
        {state.roles ? (
          <>

            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} User`} parent="Users" link="/users">
              {id != 'new' && (
                <button className="btn btn-outline-danger" onClick={() => handleDelete(id)}>Delete</button>
              )}

            </PageHeader>



            {bg_loader == true ? <BgLoader /> : (
              <>
              <form id="userfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                <div className="card card-default mb-4">
                  <div className="card-body">


                    
                      <Controller
                        name="photo"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiImageUpload
                            lcol="3"
                            icol="9"
                            max_width="200px"
                            label="Photo"
                            name="photo"
                            file={value}
                            setFile={onChange}
                            onClear={() => onChange(null)}
                            width={250}
                            height={250}
                            ratio="ratio-1x1"
                          />
                        )}
                      />
                      <UiInput
                        lcol="3"
                        icol="9"
                        label="Name"
                        required={true}
                        type="text"
                        name="name"
                        message={errors.name?.message}
                        {...register("name")}
                      />
                      <UiInput
                        lcol="3"
                        icol="9"
                        label="Username"
                        type="text"
                        required={true}
                        name="username"
                        disabled={state.user ? 'disabled' : ""}
                        message={errors.username?.message}
                        {...register("username")}
                      />
                      <UiInput
                        lcol="3"
                        icol="9"
                        label="Mobile"
                        type="text"
                        name="mobile"
                        message={errors.mobile?.message}
                        {...register("mobile")}
                      />
                      <UiInput
                        lcol="3"
                        icol="9"
                        label="Email"
                        type="text"
                        name="email"
                        message={errors.email?.message}
                        {...register("email")}
                      />
                      {state.user ? (
                        <div className="row">
                          <div className="col-sm-3">
                            <label className="form-label">Change Password</label>
                            </div>
                            <div className="col-sm-9">
                          <UiToggle
                            id="change_password"
                            name="change_password"
                            
                            {...register("change_password")}
                          />
                          </div>
                          <input type="hidden" value={id} name="id" />
                        </div>
                      ) : (
                        <input type="hidden" name="change_password" {...register("change_password")} />
                      )}
                      <UiInput
                        lcol="3"
                        icol="9"
                        required={true}
                        label="Password"
                        type="password"
                        name="password"
                        disabled={watchPass == true ? '' : "disabled"}
                        message={errors.password?.message}
                        {...register("password")}
                      />
                      <UiInput
                        lcol="3"
                        icol="9"
                        required={true}
                        label="Confirm Password"
                        type="password"
                        name="password1"
                        disabled={watchPass == true ? '' : "disabled"}
                        message={errors.password1?.message}
                        {...register("password1")}
                      />
                      <UiSelect
                        lcol="3"
                        icol="9"
                        required={true}
                        name="role"
                        label="Role"
                        message={errors.role?.message}
                        options={state.roles}
                        {...register("role")}
                      />
                      <div className="row">
                        <div className="col-sm-3">
                          <label className="form-label">Is Active?</label>
                        </div>
                        <div className="col-sm-9">
                          <UiToggle
                            id="status"
                            name="status"

                            {...register("status")}
                          />
                        </div>
                      </div>


                     

                  </div>
                </div>
                <UiButton loading={state["loader"]} title="Save" />
                    </form>
                {state.user && (
                  <div className="card card-deafult mt-4">
                    <div className="card-header">
                      Two Step Authentication
                    </div>
                    <div className="card-body">
                      {state.user.twostep ? (
                        <div><p className="text-success">Two Step App Authentication enabled for this user.</p>
                          <button className="btn btn-outline-danger" onClick={() => removeTwoStep(id)}>Remove Two Step App Authentication</button>
                        </div>
                      ) : (
                        <p className="text-muted">Two Step App Authentication is not enabled for this user.</p>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>

        ) : <BgLoader />}
      </div>
      <OverlayLoader visible={loader} />
    </>
  );
}
