import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiTextArea, UiInput, UiButton, UiSelect, UiRSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useStore } from "../state.js";




const Lead = NiceModal.create(
  ({ data }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const admin_state = useStore((state) => state.admin);
    const schema = yup.object().shape({
      name: yup.string().required("This is required field"),
      source: yup.string().required("This is required field"),
      tag: yup.string().required("This is required field"),
      email: yup.string().nullable().when('email', {
        is: (value) => value?.length,
        then: (rule) => rule.email("Enter valid email address")

      }),
      email2: yup.string().nullable().when('email2', {
        is: (value) => value?.length,
        then: (rule) => rule.email("Enter valid email address")

      }),
      mobile: yup.string().nullable().when('mobile', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter 10 digit mobile number")
          .min(10, "Enter 10 digit mobile number")
          .max(10, "Enter 10 digit mobile number"),
      }),
      mobile2: yup.string().nullable().when('mobile2', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter 10 digit mobile number")
          .min(10, "Enter 10 digit mobile number")
          .max(10, "Enter 10 digit mobile number"),
      }),

    }, [["mobile", "mobile"], ["email", "email"], ["mobile2", "mobile2"], ["email2", "email2"]]);
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#leadform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/z_update_lead",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {


      if (data) {

        reset(data);

      }

    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Lead" : "New Lead"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="leadform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>

            <UiInput
              label="Name"
              type="text"
              required={true}
              name="name"
              message={errors.name?.message}
              {...register("name")}
            />
            <UiInput
              label="Company"
              type="text"
              name="company"
              message={errors.company?.message}
              {...register("company")}
            />
            <div className="row">

              <div className="col-6">
                <UiInput
                  label="Mobile"
                  type="text"
                  name="mobile"
                  message={errors.mobile?.message}
                  {...register("mobile")}
                />
              </div>
              <div className="col-6">
                <UiInput
                  label="Email"
                  type="text"
                  name="email"
                  message={errors.email?.message}
                  {...register("email")}
                />
              </div>
            </div>

            <div className="row">

              <div className="col-6">
                <UiInput
                  label="Mobile 2"
                  type="text"
                  name="mobile2"
                  message={errors.mobile2?.message}
                  {...register("mobile2")}
                />
              </div>
              <div className="col-6">
                <UiInput
                  label="Email 2"
                  type="text"
                  name="email2"
                  message={errors.email2?.message}
                  {...register("email2")}
                />
              </div>
            </div>


            <UiInput
              label="Address"
              type="text"
              name="address"
              message={errors.address?.message}
              {...register("address")}
            />

            <div className="row">
              <div className="col-6">
                <UiSelect
                  label="Source"
                  name="source"
                  required={true}
                  options={admin_state.sources}
                  message={errors.source?.message}
                  {...register("source")}
                />
              </div>

              <div className="col-6">
                <UiSelect
                  label="Tag"
                  name="tag"
                  required={true}
                  options={admin_state.tags}
                  message={errors.tag?.message}
                  {...register("tag")}
                />
              </div>
            </div>


            <Controller
              name="remark"
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <UiTextArea

                  onChange={onChange}
                  value={value}
                  ref={ref}
                  label="Remark"
                  placeholder="Type your message"
                  name="remark"
                  message={errors.remark?.message}
                />
              )}
            />

            {admin_state.user.role == 1 &&
              <Controller
                name="user"
                control={control}
                render={({ field }) => (

                  <UiRSelect
                    options={admin_state.users}
                    className=""
                    message={errors.user?.message}
                    label="User"
                    {...field}
                  />

                )}
              />

            }

            <input type="hidden" name="id" {...register("id")}></input>




          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default Lead;
