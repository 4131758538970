import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { PageHeader, UiInput, UiButton, BgLoader, PageError, UiImageUpload } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useQuery, useQueryClient, select } from "react-query";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";



export default function Profile() {
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState();
  const queryClient = useQueryClient()



  const handleAuthSuccess = () => {

    setState({ ...state, twostep: 1 });
    Swal.fire({
      icon: "success",
      title: "Enabled",
      text: "Two Factor App authentication enabled.",
    });
  }
  const handleAuthDisable = () => {

    setState({ ...state, twostep: 0 });

  }
  const schema = yup.object().shape({
    name: yup.string().required("Name is required").max(30,'Maximum character limit is 30'),
    mobile: yup
      .string()
      .matches(/^[0-9]+$/, "Enter 10 digit mobile number")
      .required("Mobile No. is required")
      .min(10, "Enter 10 digit mobile number")
      .max(10, "Enter 10 digit mobile number"),
    email: yup
      .string()
      .email("Enter valid email address")
      .required("Email address is required"),
  });
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  useEffect(() => {
    instance
      .get("/z_profile?user")
      .then(function (response) {
        setState({ ...state, ...response.data });
      })
      .catch(function (error) { })
      .then(function () { });
  }, []);

  useEffect(() => {
    reset(state);
  }, [state]);

  var SaveProfile = (e) => {
    showLoader({ ...loader, loader: true });
    var form = document.querySelector("#profilesavefrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/z_profile",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader({ ...loader, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['users']);
          toast(response.data.msg, { type: "success" });

        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveProfile();
    //reset();
  };


  const changeProfilePass = useModal('change-profile-password');

  const cpassModal = (e) => {
    changeProfilePass.show().then((res) => {
      if (res.resolved == true) {

      }
    });
  }

  const Enable2faModal = useModal('enable-two-step-auth');

  const showEnable2fa = (e) => {
    Enable2faModal.show().then((res) => {
      if (res.resolved == true) {
        handleAuthSuccess()
      }
    });
  }

  const Disable2faModal = useModal('disable-two-step-auth');

  const showDisable2fa = (e) => {
    Disable2faModal.show().then((res) => {
      if (res.resolved == true) {
        handleAuthDisable()
      }
    });
  }
  if(state.status == 'error'){
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          Profile | Practive
        </title>
      </Helmet>
      <div>
        {state.username ? (
          <div>
            <PageHeader title="Profile" >


            </PageHeader>

            <div className="row">
              <div className="col-md-6">
            <div className="card card-default mb-4">
              <div className="card-header">Profile Details</div>
              <div className="card-body">
                <form id="profilesavefrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                <Controller
                        name="photo"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiImageUpload
                            lcol="3"
                            icol="9"
                            max_width="200px"
                            label="Photo"
                            name="photo"
                            file={value}
                            setFile={onChange}
                            onClear={() => onChange(null)}
                            width={250}
                            height={250}
                            ratio="ratio-1x1"
                          />
                        )}
                      />
                  <UiInput
                    lcol="3"
                    icol="9"
                    label="Name"
                    required={true}
                    type="text"
                    name="name1"
                    message={errors.name?.message}
                    {...register("name")}
                  />

                  <UiInput
                    lcol="3"
                    icol="9"
                    label="Mobile No."
                    required={true}
                    type="tel"
                    name="mobile"
                    message={errors.mobile?.message}
                    {...register("mobile")}
                  />

                  <UiInput
                    lcol="3"
                    icol="9"
                    label="Email"
                    type="email"
                    required={true}
                    name="email"
                    message={errors.email?.message}
                    {...register("email")}
                  />

                  <UiButton loading={loader["loader"]} title="Save" />
                </form>
              </div>
            </div>
            </div>


            <div className="col-md-6">
            <div className="card card-default">
              <div className="card-header">Security</div>
              <div className="card-body">
                <UiInput
                 lcol="3"
                 icol="9"
                  label="Username"
                  type="text"
                  value={state.username}
                  disabled="disabled"
                />

                <div className="form-group required row mb-4">
                  <label className="form-label col-sm-3">
                    Password
                  </label>
                  <div className="col-sm-9">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="password"
                        value="*********"
                        disabled
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => cpassModal()}
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>

                {state.twostep == 0 ? (
                  <div className="alert1 d-flex border-primary bg-info border-info bg-opacity-25 rounded">
                    <span className="fs-2 me-4">
                      <i className="bi bi-shield-check"></i>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                      <div className="mb-3 mb-md-0">
                        <h4 className="text-gray-900 fw-bold">
                          Secure Your Account
                        </h4>
                        <div className="text-muted1 pe-7">
                          Two-factor authentication adds an extra layer of
                          security to your account. To login, in addition you'll
                          need to provide a 6 digit code generated from your
                          authenticator app.
                        </div>
                      </div>
                      <button
                        className="btn btn-primary px-6 align-self-center text-nowrap"
                        onClick={() => showEnable2fa()}
                      >
                        Enable
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h3 className="h5 border-top mt-4 py-4">
                      Two-factor App Authentication
                    </h3>
                    <button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={() => showDisable2fa()}
                    >
                      Disable Two-factor App Authentication
                    </button>
                  </div>
                )}
              </div>
            </div>
            </div>
            </div>
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
}
