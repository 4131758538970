import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BgLoader, UiButton, UiInput } from "../../ui";
import { setStorage } from "../../helper";
import { useStore } from "../../state.js";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import logoimg from "../../practive.svg";




const TwoFA = ({ ...props }) => {
  let { id } = useParams();

  const { auth, setAuth } = useStore((state) => state);
  let navigate = useNavigate();
  const location = useLocation();
  const schema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^[0-9]+$/, "Enter 6 digit authentication code")
      .required("This field is required")
      .min(6, "Enter 6 digit authentication code")
      .max(6, "Enter 6 digit authentication code")
  });
  const [state, setState] = useState({});

  var doLogin = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#twofaotpfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/z_valid_2fa",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "invalid") {
          toast(response.data.msg, { type: "error" });
        } else if (response.data.status == "success") {
          setAuth("1");
          setStorage("token", response.data.token);
          setStorage("rtoken", response.data.rtoken);
          if (location.state && location.state.from) {
            navigate(location.state.from.pathname, { replace: true });
          } else {
            navigate("/", { replace: true });
          }

        } else if (response.data.status == "error") {
          navigate("/login", { replace: true });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    doLogin();
    //reset();
  };

  useEffect(() => {
    if (id === null || id === undefined) {
      navigate("/login", { replace: true });
    } else {
      instance
        .get("/z_valid_2fa?id=" + id)
        .then(function (response) {
          if (response.data.status == "success") {
            setState({ ...state, valid: true });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Two factor authentication link is invalid or expired!",
            }).then((result) => {
              navigate("/login", { replace: true });
            });
          }
        })
        .catch(function (error) { })
        .then(function () { });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Two Factor Authentication | Practive
        </title>
      </Helmet>
      <div className="container py-5 h-100">
        {state.valid ? (
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="mb-4 text-center">
                <img className="loginlogo" src={logoimg} />
              </div>
              <div className="card shadow-2-strong">
                <div className="card-body">
                  <h3 className="mb-5">Two Factor Authentication</h3>
                  <form id="twofaotpfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                    <UiInput
                      label="Two Factor Authentication Code"
                      type="text"
                      name="otp"
                      message={errors.otp?.message}
                      {...register("otp")}
                    />
                    <input type="hidden" name="id" value={id} />

                    <UiButton loading={state["loader"]} title="Verify" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : <BgLoader />}
      </div>
    </>
  );
};

export default TwoFA;
