import React, { useState, useEffect } from "react";
import { Outlet, useLocation, NavLink, Link, useNavigate } from "react-router-dom";
import { OverlayLoader, Avatar } from './ui'
import { AiOutlineUser, AiOutlineUserAdd } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { MdOutlineTask, MdOutlineEditCalendar, MdOutlineSpaceDashboard, MdOutlineAccountBox, MdMenu } from 'react-icons/md';
import { BiReceipt, BiCategoryAlt } from 'react-icons/bi';
import { BsGear } from 'react-icons/bs';
import { HiOutlineRefresh, HiOutlineCurrencyRupee, HiOutlineReceiptRefund, HiOutlineDocumentReport } from 'react-icons/hi';
import { GoPlus, GoTasklist } from 'react-icons/go';
import { instance } from "./axios";
import { useStore } from "./state.js";
import logoimg from "./practive.svg";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { setStorage } from "./helper";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FiGlobe, FiExternalLink } from 'react-icons/fi'
import { FaRegBell } from "react-icons/fa";

const Layout = () => {
  const [state, setState] = useState({});
  const [fullLoader, showFLoader] = useState(false);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { auth, setAuth } = useStore((state) => state);
  const setZState = useStore((state) => state.setAdmin);
  let activeStyle = {
    textDecoration: "underline",
  };
  useEffect(() => {

    instance
      .get("/z_admin")
      .then(function (response) {
        setState({ ...state, ...response.data })
        setZState(response.data)
      })


  }, []);


  const location = useLocation();


  useEffect(() => {

    setShow(false)

  }, [location]);


  const getNavLinkClass = path => {

    return location.pathname.search(path) > 0
      ? " show"
      : "";
  };

  const Logout = () => {
    showFLoader(true)
    instance
      .get("/z_logout")
      .then(function (response) {
        showFLoader(false)
        setStorage("token", '');
        setStorage("rtoken", '');
        setAuth(null);
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const QuickAddBtn = (props) => {
    const { action, title, ...other } = props;
    return (
      <OverlayTrigger overlay={<Tooltip >New {title}</Tooltip>}>

        <button className="btn menuaddnewbtn" onClick={action}><GoPlus /></button>
      </OverlayTrigger>
    );
  }


  return (
    <>
      <header className="navbar navbar-light sticky-top bg-light flex-md-nowrap py-2 shadow-sm px-3">
        <button className="btn btn-default d-lg-none" onClick={() => setShow(true)}><MdMenu className="fs-1" /></button>
        <a className="navbar-brand col-md-2 me-0 fs-6" onClick={() => navigate("/")}>
          <img style={{ maxWidth: '100px' }} src={logoimg} />
        </a>

        <div className="btn-group">


          <a
            style={{ cursor: 'pointer' }}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {state.user &&
              <Avatar name={state.user.name} src={state.user.photo} className="bg-secondary" size="30" />
            }
          </a>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <NavLink className="dropdown-item" to="/profile">
                Profile
              </NavLink>
            </li>

            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button className="dropdown-item" onClick={() => Logout()}>
                Logout
              </button>
            </li>
          </ul>
        </div>
      </header>

      <div className="container-fluid">

        <div className="row">
          <Offcanvas show={show} onHide={() => setShow(false)} responsive="lg" className="bg-light" >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title><img style={{ maxWidth: '100px' }} src={logoimg} /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body >

              {state.user &&
                <nav
                  id="sidebarMenu"
                  className="col-md-3 col-lg-2 d-md-block bg-light sidebar"
                >
                  <div className="position-sticky pt-lg-3 sidebar-sticky px-lg-3">





                    <div className="menu-itm">
                      <NavLink to="/" >
                        <span className="menu-icon">
                          <MdOutlineSpaceDashboard />
                        </span>
                        <span className="menu-title">Dashboard</span>
                      </NavLink>
                    </div>

                    <div className="menu-itm">
                      <NavLink to="/leads" >
                        <span className="menu-icon">
                          <MdOutlineTask />
                        </span>
                        <span className="menu-title">Leads</span>

                      </NavLink>

                    </div>
                    {state.user.role == 1 && <>
                    <div className="menu-itm">
                      <NavLink to="/quotes" >
                        <span className="menu-icon">
                          <HiOutlineCurrencyRupee />
                        </span>
                        <span className="menu-title">Quotes</span>

                      </NavLink>

                    </div>
                    </>}
                    <div className="menu-itm">
                      <NavLink to="/todos"><span className="menu-icon">
                        <GoTasklist />
                      </span>
                        <span className="menu-title">To-Do</span></NavLink>
                    </div>
                    {state.user.role == 1 && <>
                    <div className="menu-itm">
                      <NavLink to="/clients" >
                        <span className="menu-icon">
                          <AiOutlineUser />
                        </span>
                        <span className="menu-title">Clients</span>
                      </NavLink>
                      <QuickAddBtn title="Client" action={(e) => navigate("/client/new")}></QuickAddBtn>
                    </div>
                    <div className="menu-itm">
                      <NavLink to="/trials" >
                        <span className="menu-icon">
                          <AiOutlineUserAdd />
                        </span>
                        <span className="menu-title">Trials</span>
                      </NavLink>
                      <QuickAddBtn title="Client" action={(e) => navigate("/client/new")}></QuickAddBtn>
                    </div>
                    
              
                

                    <div className="menu-itm">
                      <NavLink to="/updates"><span className="menu-icon">
                        <FaRegBell />
                      </span>
                        <span className="menu-title">Updates</span></NavLink>
                    </div>
                    <div className="menu-itm">
                      <NavLink to="/users"><span className="menu-icon">
                        <MdOutlineAccountBox />
                      </span>
                        <span className="menu-title">Users</span></NavLink>
                    </div>
                    </>}
        
                  </div>
                </nav>
              }
            </Offcanvas.Body>
          </Offcanvas>
          <main className="ms-sm-auto col-lg-10 px-md-4">
            <section className="content d-inline-block w-100">
              <div className="container-fluid mb-4">
                {state.is_active == 0 && <div className="alert alert-danger mt-4">Your subscription has expired. <Link to="/account" className="btn btn-outline-dark btn-sm ms-2">Renew Now</Link></div>}
                {state.user &&
                  <Outlet context={[state, setState]} />
                }
              </div>
            </section>
          </main>
        </div>
      </div>
      {fullLoader == true && <OverlayLoader visible={true} />}
    </>
  );
};

export default Layout;
