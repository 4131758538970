import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiTextArea, UiInput, UiButton, UiSelect, UiDatePicker } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";




const DemoReq = NiceModal.create(
  ({ data, lid, sts }) => {
    const [state, setState] = useState({});
    const modal = useModal();
    const schema = yup.object().shape({
      status: yup.string().required("This is required field"),
    
      date: yup.string().required("This is required field")


    });
    const {
      register,
      handleSubmit,
      control,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      SaveData();
      //reset();
    };
    var SaveData = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#demoform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/z_update_demo",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };
    useEffect(() => {


      if (data) {

        reset(data);
      }


    }, []);

    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    const watch_status = watch('status');
    return (
      <BootstrapModal {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit Demo Schedule" : "New Demo Schedule"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <form id="demoform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <BootstrapModal.Body>





            <UiSelect
              label="Status"
              name="status"
              required={true}
              options={['Pending', 'Completed', 'Cancelled']}
              message={errors.status?.message}
              {...register("status")}
            />
            <Controller
              name="date"
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <UiDatePicker
                  label="Demo Schedule on"
                  required={true}
                  onChange={onChange}
                  showTimeSelect
                  default_val={value}
                  minDate={new Date()}
                  message={errors.date?.message}
                  dateFormat="dd-MM-yyyy HH:mm"
           

                  ref={ref}
                  name={name}
                />
              )}
            />
        
     
            <Controller
              name="remark"
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <UiTextArea

                  onChange={onChange}
                  value={value}
                  ref={ref}
                  label="Remark"
                  placeholder="Type your message"
                  name="remark"
                  message={errors.remark?.message}
                />
              )}
            />

            <input type="hidden" value={lid} name="lead_id"></input>

            <input type="hidden" name="id" {...register("id")}></input>




          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <UiButton loading={state["loader"]} title="Save" />
            <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
          </BootstrapModal.Footer>
        </form>
      </BootstrapModal>
    );
  }
);

export default DemoReq;
