import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, UiRichText, UiInput, UiButton, PageHeader, BgLoader, OverlayLoader, UiImageUpload, PageError } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import { Helmet } from "react-helmet";


export default function Update() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [bg_loader, setBGLoader] = useState(false);
  const [loader, showLoader] = useState(false);


  const schema = yup.object().shape({
    type: yup.string().required("This is required"),
    title: yup.string().required("This is required"),
    //description: yup.string().required("This is required"),






  }, []);



  var handleSave = (e) => {
    console.log(e)
    setState({ ...state, loader: true });
    var form = document.querySelector("#updatefrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/z_update_updateitm",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          navigate(-1)
          queryClient.invalidateQueries(['updates'])
          toast(response.data.msg, { type: "success" });
        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    control,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };


  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/z_update_updateitm?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['updates'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    instance
      .get("/z_get_update_updateitm?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {

        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }




  useEffect(() => {
    fetchData();

  }, []);

  useEffect(() => {
    reset(state.update)
  }, [state.update]);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Update | Practive
        </title>
      </Helmet>
      <div>
        {state.update ? (
          <>

            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Update`} parent="Updates" link="/updates">
              {id != 'new' && (
                <button className="btn btn-outline-danger" onClick={() => handleDelete(id)}>Delete</button>
              )}

            </PageHeader>



            {bg_loader == true ? <BgLoader /> : (
              <>
                <form id="updatefrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                  <div className="card card-default mb-4">
                    <div className="card-body">



                      <Controller
                        name="photo"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiImageUpload
                            lcol="3"
                            icol="9"
                            max_width="200px"
                            label="Photo"
                            name="photo"
                            file={value}
                            setFile={onChange}
                            onClear={() => onChange(null)}
                            width={800}
                            height={450}
                            ratio="ratio-16x9"
                          />
                        )}
                      />
                      <UiInput
                        lcol="3"
                        icol="9"
                        label="Type"
                        required={true}
                        type="text"
                        name="type"
                        message={errors.type?.message}
                        {...register("type")}
                      />
                      <UiInput
                        lcol="3"
                        icol="9"
                        label="Title"
                        required={true}
                        type="text"
                        name="title"
                        message={errors.title?.message}
                        {...register("title")}
                      />

                      <Controller
                        name="description"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiRichText
                            lcol="3"
                            icol="9"
                            required={true}
                            onChange={onChange}
                            value={value}
                            ref={ref}
                            label="Description"
                            name="description"
                            message={errors.description?.message}
                          />
                        )}
                      />


                      <UiInput
                        lcol="3"
                        icol="9"
                        label="URL"
                        type="text"
                        name="url"
                        message={errors.url?.message}
                        {...register("url")}
                      />
                      <UiInput
                        lcol="3"
                        icol="9"
                        label="CTA"
                        type="text"
                        name="cta"
                        message={errors.cta?.message}
                        {...register("cta")}
                      />

                      <UiInput
                        lcol="3"
                        icol="9"
                        label="Mail Subject"
                        type="text"
                        name="subject"
                        message={errors.subject?.message}
                        {...register("subject")}
                      />


                      <Controller
                        name="body"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiRichText
                            lcol="3"
                            icol="9"
                            onChange={onChange}
                            value={value}
                            ref={ref}
                            label="Mail Body"
                            name="body"
                            message={errors.body?.message}
                          />
                        )}
                      />




                      <input type="hidden" name="id" value={id} />



                    </div>
                  </div>
                  <UiButton loading={state["loader"]} title="Save" />
                </form>

              </>
            )}
          </>

        ) : <BgLoader />}
      </div>
      <OverlayLoader visible={loader} />
    </>
  );
}
