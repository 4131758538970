import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, InLoader, UiDateRange, UiActioButton, UiActionDropDown, UiInput, UiSelect, Avatar, PageError, Toastcontent } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { AiOutlineMore } from 'react-icons/ai'
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { BsTelephoneOutboundFill, BsWhatsapp } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { DropdownItem } from '@atlaskit/dropdown-menu';



export default function Leads() {
  let navigate = useNavigate();
  const location = useLocation();
  const admin_state = useStore((state) => state.admin);
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);


  const schema = yup.object().shape(
    {

    }
  );
  const Dtcheckbox = forwardRef(({ ...props }, ref) => (
    <input ref={ref} className="form-check-input" type="checkbox" {...props} />
  ));

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { isDirty } = formState;


  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/leads?" + u, { replace: true })
    } else {
      navigate("/leads?" + u)
    }


  };

  const userModal = useModal('lead');

  const showAddModal = (row) => {

    userModal.show({ data: row }).then((res) => {
      queryClient.invalidateQueries(['leads'])
    });

  }

  const handleDelete = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setTableDisable(true)
        instance
          .get("/delete_client?delete=" + tid)
          .then(function (response) {
            setTableDisable(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['leads'])
              clearSelection()
              toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "success" });
            } else {
              toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "error" });
            }


          })

      }
    })




  }



  const headers = [
    { label: "File No.", key: "file" },
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "Type", key: "type" },
    { label: "Group", key: "group_name" },
    { label: "Status", key: "status" },
    { label: "Users", key: "users" },


  ];

  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});

  const handleRowSelected = React.useCallback(state => {
    setState1({ selected: state.selectedRows })
  }, []);


  async function fetchData(filter) {

    const { data } = await instance.get(
      "/z_leads" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    type: null,
    tag: '',
    users: null,
    service: null,
    source: [],
    status: '',
    demo_date: '',
    followup_date: '',
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/leads", { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);



  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      width: '100px',
      cell: row => (<span className="text-truncate" title={row.date}>{row.date}</span>)
    },
    {
      name: 'Name',
      selector: row => row.name,
      cell: row => (<Link to={`/view-lead/${row.id}`}><b>{row.name}</b></Link>),
      sortable: true,
      sortField: 'name',
      id: 'name',
      width: '200px'
    },
    {
      name: 'Email',

      width: '180px',
      selector: row => row.email,
      cell: row => (<span className="text-truncate" title={row.email}>{row.email}</span>)
    },

    {
      name: 'Mobile',

      width: '180px',
      selector: row => row.mobile,
      cell: row => (<>{row.mobile && <span className="d-flex">{row.mobile} <OverlayTrigger overlay={<Tooltip >Make Call</Tooltip>}>
        <a href={`tel:${row.mobile}`} target="_blank" className="ms-3"><span className="sicon"><BsTelephoneOutboundFill /></span></a>
      </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip >Send WhatsApp</Tooltip>}>
          <a className="ms-2 text-success" href={`https://wa.me/${row.mobile}`} target="_blank"><span className="sicon"><BsWhatsapp /></span></a>
        </OverlayTrigger></span>}</>)
    },
    {
      width: '140px',
      name: 'Follow-up on',
      selector: row => row.due_date,
      sortable: true,
      sortField: 'due_date',
      id: 'due_date',
      cell: row => (<span className="text-truncate" title={row.due_date}>{row.due_date}</span>)
    },

    {
      name: 'Status',
      sortable: true,
      sortField: 'status',
      id: 'status',
      width: '100px',
      selector: row => row.status,
      cell: row => (<span className={`${row.status == 'Converted' ? 'text-success' : row.status == 'Closed' ? 'text-danger' : 'text-warning'}`}>{row.status}</span>)
    },
    {
      name: 'Tag',
      sortable: true,
      sortField: 'tag',
      id: 'tag',
      width: '100px',
      selector: row => row.tag,
      cell: row => (<span style={{color:row.color}}>{row.tag}</span>)
    },
    {
      name: 'User',
      selector: row => row.user,
      width: '150px',
    },

    {
      name: 'Source',
      width: '120px',
      sortable: true,
      sortField: 'source',
      id: 'source',
      selector: row => row.source,
      cell: row => (<span className="text-truncate" title={row.source}>{row.source}</span>)
    },
    {
      name: 'Last Remark',
      selector: row => row.fw_remark,
      cell: row => (<span className="text-truncate" title={row.fw_remark}>{row.fw_remark}</span>)
    },
    {
      width: '140px',
      name: 'Demo on',
      sortable: true,
      sortField: 'demo_on',
      id: 'demo_on',
      selector: row => row.demo_on,
    },
    {
      name: 'Demo Status',
      sortable: true,
      sortField: 'demo_status',
      id: 'demo_status',
      width: '110px',
      selector: row => row.demo_status,
      cell: row => (<span className={`${row.demo_status == 'Completed' ? 'text-success' : row.demo_status == 'Cancelled' ? 'text-danger' : 'text-warning'}`}>{row.demo_status}</span>)

    },
    {
      name: 'Company',
      selector: row => row.company,
      cell: row => (<span className="text-truncate" title={row.company}>{row.company}</span>)
    },
    {
      name: 'Address',
      selector: row => row.address,
      cell: row => (<span className="text-truncate" title={row.address}>{row.address}</span>)
    },

  ];

  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/leads?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/leads?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/leads?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/leads?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["leads", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });

  const clearSelection = () => {
    setToggleCleared(!toggleCleared)
    setState1({ selected: [] })
  }
  useEffect(() => {


    const defaultValues1 = {
      date: filter.date,
      tag: filter.tag,
      followup_date: filter.followup_date,
      demo_date: filter.demo_date,
      search: filter.search,
      status: filter.status,
      source: filter.source,
      users: admin_state.users && filter.users ? admin_state.users.filter(({ value }) => filter.users.split(',').includes(value.toString())) : null,

    };
    reset(defaultValues1)

  }, [filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Leads | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Leads" >
            {data.items &&
              <CSVLink data={data.items} filename={"Leads.csv"} headers={headers} className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`} >Export</CSVLink>
            }
            <UiButton className="btn btn-primary" onClick={() => showAddModal()} title="New" icon="add" ></UiButton>


          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

            <div className="p-3 bg-light rounded mb-4 ">

              <div className="row">

                <div className="col-md-3">
                  <UiInput
                    label="Search"
                    type="text"

                    name="search"

                    {...register("search")}
                  />
                </div>









                <div className={`col-md-3`}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (

                      <UiSelect
                        options={['Open', 'Closed', 'Converted']}
                        className=""
                        message={errors.status?.message}
                        label="Status"
                        {...field}
                      />

                    )}
                  />
                </div>
                <div className={`col-md-3`}>
                  <Controller
                    name="demo_status"
                    control={control}
                    render={({ field }) => (

                      <UiSelect
                        options={['Pending', 'Completed', 'Cancelled']}
                        className=""
                        message={errors.demo_status?.message}
                        label="Demo Status"
                        {...field}
                      />

                    )}
                  />
                </div>

                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <Controller
                    name="source"
                    control={control}
                    render={({ field }) => (

                      <UiSelect
                        options={admin_state.sources}
                        className=""
                        message={errors.source?.message}
                        label="Source"
                        {...field}
                      />

                    )}
                  />
                </div>
                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <Controller
                    name="tag"
                    control={control}
                    render={({ field }) => (

                      <UiSelect
                        options={admin_state.tags}
                        className=""
                        message={errors.tag?.message}
                        label="Tag"
                        {...field}
                      />

                    )}
                  />
                </div>
                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <Controller
                    name="users[]"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        defaultValue={null}
                        options={admin_state.users}
                        className=""
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        message={errors.users?.message}
                        label="User"
                        {...field}
                      />

                    )}
                  />

                </div>
                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <label className="form-label">Creation Date</label>
                  <Controller
                    name="date"
                    control={control}
                    defaultValue="All Time"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDateRange

                        onUpdate={onChange}
                        value={value}
                        ref={ref}
                        name={name}

                      />
                    )}
                  />

                </div>


                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <label className="form-label">Follow-up Date</label>
                  <Controller
                    name="followup_date"
                    control={control}
                    defaultValue="All Time"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDateRange

                        onUpdate={onChange}
                        value={value}
                        ref={ref}
                        name={name}

                      />
                    )}
                  />

                </div>

                <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  <label className="form-label">Demo Date</label>
                  <Controller
                    name="demo_date"
                    control={control}
                    defaultValue="All Time"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDateRange

                        onUpdate={onChange}
                        value={value}
                        ref={ref}
                        name={name}

                      />
                    )}
                  />

                </div>




                <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>

                </div>





                <div className="col-md-3" style={{ paddingTop: '28px', minWidth: '200px' }}>
                  <div className="d-flex justify-content-end">
                    {zstate.more_filter == true ? (
                      <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                    ) : (

                      <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                    )}

                    <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                    <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                  </div>

                </div>
              </div>



            </div>

          </form>

          {state1.selected && state1.selected.length > 0 && (
            <div className="bulkaction">

            </div>
          )}
          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body tablecard">

              <DataTable
                columns={columns}
                pagination
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                clearSelectedRows={toggleCleared}
                onSort={handleSort}
                sortServer
                persistTableHead
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="Leads" />}
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
