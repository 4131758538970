import React, { useState, useEffect, useRef } from "react";
import { instance } from "../../axios";
import { PageHeader, UiToggle, UiTimeDisplay, UiAvatarName, UiButton, PageError, BgLoader } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";
import { useQueryClient } from 'react-query';
import { rupees } from "../../helper";
import { InvoiceTemplate } from '../../invoice'
import { QRCodeSVG } from 'qrcode.react';
import logoimg from "../../practive.svg";
import ReactToPrint from "react-to-print";



export default function ViewQuote() {
  let { id } = useParams();
  let componentRef = useRef();

  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  const [vloader, showVLoader] = useState(false);
  const [bg_loader, setBGLoader] = useState(false);
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader({ ...loader, delete: true })
        instance
          .get("/z_update_quote?delete=" + id)
          .then(function (response) {
            showLoader({ ...loader, delete: false })
            if (response.data.status == 'success') {

              queryClient.invalidateQueries(['quotes'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }
  const quotemodal = useModal('quote');

  const showQuote = (row) => {
    quotemodal.show({ data: row, lid: id }).then((res) => {
      fetchData();
    });
  }


  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/z_view_quote?id=" + id)
      .then(function (response) {
        setBGLoader(false)

        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);



  

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }




  return (
    <>
      <Helmet>
        {state.quote && (
          <title>
            #{id} | Quotes | Practive
          </title>
        )}

      </Helmet>
      <div>
        {state.quote ? (
          <>

            <PageHeader title={`#${state.quote.id}`} parent="Quotes" link="/quotes" stacked={true}>

            <ReactToPrint
                trigger={() => <button className="btn btn-outline-primary">Print</button>}
                content={() => componentRef.current}
              />

              <UiButton className="btn btn-outline-dark ms-2"  onClick={() => showQuote(state.quote)} title="Edit" />
              <UiButton className="btn btn-outline-danger ms-2" loading={loader.delete} onClick={() => handleDelete(id)} title="Delete" />
             
            </PageHeader>





            {bg_loader == true ? <BgLoader /> : (
              <>

                <div className="card card-default " >
                  <div className="card-body bg-white" ref={componentRef}>

                    <div className="row">
                      <div className="col-sm-6">

                        <img style={{ maxWidth: '200px' }} className="mb-4" src={logoimg} />

                        <p style={{ maxWidth: '400px' }} className="mb-0"><b className="fs-5 pt-4">{state.quote.company_name}</b><br></br>
                          {state.quote.company_address}<br></br>{state.quote.company_state}
                          {state.quote.company_mobile && (<><br></br><b>Mobile: </b>{state.quote.company_mobile}</>)}
                          {state.quote.company_email && (<><b>  |   Email: </b>{state.quote.company_email}</>)}
                        </p>
                        {state.quote.company_gstin && (
                          <p><b>GSTIN: </b>{state.quote.company_gstin}</p>
                        )}


                      </div>
                      <div className="col-sm-6 text-sm-end">
                        <div className="text-grey-m2">
                          <div className="h1 text-600">
                            Quote
                          </div>

                        </div>
                      </div>
                    </div>



                    <div className="row border-top pt-3">
                      <div className="col-sm-9">
                        <div>
                          <p className="mb-1">To:</p>
                          <span className="fw-semibold">{state.quote.company ? state.quote.company : state.quote.name}</span>
                        </div>
                        <div className="text-grey-m2">
                          <p style={{ maxWidth: '400px' }}>{state.quote.address && state.quote.address}
                            {state.quote.mobile && (<><br></br><b>Mobile: </b>{state.quote.mobile}</>)}
                            {state.quote.email && (<><br></br><b>Email: </b>{state.quote.email}</>)}
                          </p>


                        </div>
                      </div>


                      <div className="col-sm-3 text-sm-end">
                        <div className="">
                          <table className="table table-borderless table-sm ">
                            <tbody>
                              <tr>
                                <td>Quote No.:</td>
                                <td className="text-end ms-3">
                                  {state.quote.id}
                                </td>
                              </tr>
                              <tr>
                                <td>Date:</td>
                                <td className="text-end">
                                  {state.quote.date}
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive mt-4 mb-9">
                      <table className="table mb-3">
                        <thead>
                          <tr className="border-bottom text-secondary text-uppercase">
                            <th>Description</th>

                            <th className="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.quote.item && state.quote.item.map((val, i) => (
                            <tr key={i} >
                              <td>
                                {val.title}</td>

                              <td className="text-end">{rupees(val.amount)}</td>
                            </tr>
                          ))}


                        </tbody>
                      </table>

                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {state.quote.total_in_words}
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-end mt-3">

                          <table className="table table-borderless table-sm" style={{ maxWidth: '250px' }}>
                            <tbody>
                              <tr className="text-secondary">
                                <td>Subtotal:</td>
                                <td className="text-end">
                                  {rupees(state.quote.subtotal)}
                                </td>
                              </tr>


                              <tr className="text-secondary">
                                <td>Discount:</td>
                                <td className="text-end">
                                  -{rupees(state.quote.discount)}
                                </td>
                              </tr>
                              <tr >
                                <td>Total:</td>
                                <td className="text-end">
                                  {rupees(state.quote.subtotal-state.quote.discount)}
                                </td>
                              </tr>
                              <tr className="text-secondary">
                                <td>GST:</td>
                                <td className="text-end">
                                  {rupees(state.quote.tax)}
                                </td>
                              </tr>






                              <tr className="fw-bold border-top border-bottom">
                                <td className="fs-5">Grand Total:</td>
                                <td className="text-end fs-5 fw-semibold">
                                  {rupees(state.quote.total)}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </div>



                      </div>
                    </div>





                    <div className="border p-3" style={{ maxWidth: '400px' }}>
                      <div className="d-flex" >
                        <div className="col1 flex-grow-1 pe-2">
                          <p><b className="">Scan QR code to pay using any UPI app.</b><br></br><br></br>UPI ID<br></br>mative@icici</p>
                        </div>
                        <div className="col1">
                          <QRCodeSVG value={`upi://pay?pa=mative@icici&pn=Practive&am=${state.quote.total}&tn=Quote No. ${state.quote.id}`} size="100" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-7">
                        <div className="mt-4" >
                          <b>Company Bank Details</b>
                          <p><b>UPI ID:</b> mative@icici<br />
                            <b>PAN Number:</b> AAMCM2538C<br />
                            <b>Account Name:</b> MATIVE PRIVATE LIMITED<br />
                            <b>Account Number:</b> 682405601620<br />
                            <b>Bank Name:</b> ICICI Bank<br />
                            <b>IFS Code:</b> ICIC0006824</p>
                        </div>
                      </div>
                      <div className="col-md-5 text-end pt-4">

                      </div>
                    </div>

<p className="text-secondary text-center">THANK YOU FOR YOUR BUSINESS!</p>
<p className="text-center">
<a href="https://www.practive.in" >www.practive.in</a>
</p>
                  </div>
                </div>

              </>
            )}
          </>

        ) : <BgLoader />}


      </div>
    </>
  );
}
