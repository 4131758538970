import React, { forwardRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { UiSelect, UiToggle, UiDatePicker, UiActioButton, UiButton, UiRSelect, BgLoader, Avatar } from "./ui";

import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import { format, formatRelative, parse, setDate } from 'date-fns';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from "react-icons/ri";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdOutlineCalendarToday, MdOutlineRepeat } from "react-icons/md";
import enGB from 'date-fns/locale/en-GB';

import Swal from "sweetalert2";

const formatRelativeLocale = {
  lastWeek: 'dd-MMM-yyyy',
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: 'dd-MMM-yyyy',
  other: 'dd-MMM-yyyy',
};

const locale = {
  ...enGB,
  formatRelative: (token) => formatRelativeLocale[token],
};
export function TodoItem(props) {

  const { val, key1, onComplete, hideDetails, onView,fullHeight, onEdit, onDelete } = props;

  return (

    <div key={key1} className={`todoitm w-100 border-top py-2 ${val.loading && 'opacity-25'} ${fullHeight == true && 'h-100'}`}>
      <div className={`d-flex align-items-center ${fullHeight == true && 'h-100'}`}>

        {val.status == 1 ? (
          <OverlayTrigger overlay={<Tooltip >Mark Incomplete</Tooltip>} >
            <div className={`d-inline-flex fs-3 text-success ${val.can_complete == 0 && 'pe-none'}`} onClick={() => onComplete(val.id, 0)}>
              <RiCheckboxCircleFill />
            </div>
          </OverlayTrigger>
        ) : (<OverlayTrigger overlay={<Tooltip >Mark Complete</Tooltip>} ><div className={`d-inline-flex fs-3  ${val.can_complete == 1 ? 'text-muted' : 'pe-none text-light'}`} onClick={() => onComplete(val.id, 1)} disabled={val.can_complete == 1 ? false : true}><RiCheckboxBlankCircleLine /></div></OverlayTrigger>)}

        <div className="flex-grow-1 ms-2" style={{minWidth:'0'}}>
          <span className="d-block c-pointer text-truncate" onClick={() => onView(val)}>{val.title}</span>
          {val.details && !hideDetails && <small onClick={() => onView(val)} className="d-block text-truncate2 text-muted c-pointer">{val.details}</small>}

          {val.date && (
            <span className="d-inline-flex text-muted align-items-center small me-2 mt-1">
              <MdOutlineCalendarToday className="me-1" />
              {formatRelative(parse(val.date, "dd-MM-yyyy", new Date()), new Date(), { locale })}
              {val.repeat_every && <MdOutlineRepeat className="ms-2 text-success" />}

            </span>
          )}
          {val.is_assign == 1 && (
            <span className="d-inline-flex text-muted align-items-center small mt-1">
              <OverlayTrigger overlay={<Tooltip >{val.name}</Tooltip>} >
                <span className="me-1 d-flex">
                  <Avatar name={val.name} className="bg-secondary d-inline-flex " src={val.photo} size="15"/>
                </span>
              </OverlayTrigger>
              {val.name}
            </span>
          )}

        </div>
        <div className="todoactions">


          {val.can_edit == true && (
            <UiActioButton title="Edit" action={() => onEdit(val)} />

          )}
          {val.can_delete == true && (
            <UiActioButton title="Delete" action={() => onDelete(val.id)} />

          )}
        </div>
      </div>
    </div>

  )
}

