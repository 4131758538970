import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiSelect, UiDatePicker, BgLoader, UiTextArea } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';
import { useStore } from "../state.js";
import { useNavigate, useLocation } from "react-router-dom";




const AddTodo = NiceModal.create(
  ({ title, taskid, action, bgColor, note = "", data }) => {
    const [input, setInput] = useState(note);
    const [state, setState] = useState({});
    const modal = useModal();
    const admin_state = useStore((state) => state.admin);
    const schema = yup.object().shape({
      title: yup.string().required("Enter to-do title").max(30, 'Maximum character limit is 30'),
      is_duedate: yup.bool(),
      is_repeat: yup.bool(),
      is_assign: yup.bool(),
      details: yup.string().max(100, 'Maximum character limit is 100'),
      date: yup.string().nullable().when('is_duedate', {
        is: true,
        then: (rule) => rule.required("Select due date")
      }),
      repeat_interval: yup.string().nullable().when(['is_duedate', 'is_repeat'], {
        is: (val1, val2) => val1 == true && val2 == true,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter interval")
          .required("Enter interval")
          .min(1, "Enter interval")
      }),
      repeat_every: yup.string().nullable().when(['is_duedate', 'is_repeat'], {
        is: (val1, val2) => val1 == true && val2 == true,
        then: (rule) => rule.required("Select frequency")
      }),

      user: yup.string().when('is_assign', {
        is: true,
        then: (rule) => rule.required("Select user")
      }),
    });
    const {
      register,
      handleSubmit,
      control,
      getValues,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
      handleSave();
    };


    const duedate = watch('is_duedate')
    const repeat = watch('is_repeat')
    const assign = watch('is_assign')



    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#todoform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/z_update_todo",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };


    const fetchData = () => {
      var id = 0;
      if (data && data.user) {
        id = data.user
      }
      instance
        .get("/z_get_users?s=" + id)
        .then(function (response) {
          if (response.data.status == 'success') {
            setState({ ...state, ...response.data });
          } else {
            toast(response.data.msg, { type: "error" });
          }


        })
        .catch(function (error) { })
        .then(function () { });

    }

    useEffect(() => {
      if (data) {

        reset(data);
      }

    }, [data]);

    useEffect(() => {
      fetchData();

    }, []);


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)} >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{data ? "Edit To-Do" : "New To-Do"}</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.users ? (
          <form id="todoform" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>


              <UiInput
                label="To-Do"
                type="text"
                required={true}
                name="title"
                message={errors.title?.message}
                {...register("title")}
              />
              <UiTextArea

                label="Details"
                name="details"
                message={errors.details?.message}
                {...register("details")}
              />

              <div className="mb-3">

                <input type="checkbox" className="form-check-input" id="btn-check-2-outlined" autoComplete="off" name="is_duedate" {...register("is_duedate")} />
                <label className="form-check-label ms-2 user-select-none" htmlFor="btn-check-2-outlined">Set due date</label>
              </div>
              {duedate == true && (
                <>
                  <Controller
                    name="date"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        label="Date"
                        required={true}
                        onChange={onChange}
                        default_val={value}
                        message={errors.date?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />

                  <UiToggle
                    id="repeat"
                    name="is_repeat"
                    title="Repeat"
                    {...register("is_repeat")}
                  />
                  {repeat == true && (
                    <>
                      <div className="row">

                        <div className="col-6">
                          <UiInput
                            label="Interval"
                            required={true}
                            type="text"
                            name="repeat_interval"
                            message={errors.repeat_interval?.message}
                            {...register("repeat_interval")}
                          />
                        </div>
                        <div className="col-6">
                          <UiSelect
                            name="repeat_every"
                            required={true}
                            label="Repeat Every"
                            message={errors.repeat_every?.message}
                            options={["Day", "Month", "Year"]}
                            {...register("repeat_every")}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {admin_state.assign_todo == 1 && <>
                <div className="mb-3">

                  <input type="checkbox" className="form-check-input" id="btn-check-1-outlined" autoComplete="off" name="is_assign" {...register("is_assign")} />
                  <label className="form-check-label ms-2 user-select-none" htmlFor="btn-check-1-outlined">Assign to user</label>
                </div>

                {assign == true && (
                  <div>
                    <UiSelect
                      required={true}
                      name="user"
                      label="Select User"
                      message={errors.user?.message}
                      options={state.users}
                      {...register("user")}
                    />

                  </div>
                )}
              </>}

              {taskid && <input type="hidden" value={taskid} name="task_id" />}

              {data && (<input type="hidden" value={data.id} name="id"></input>)}




            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Save" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default AddTodo;
