import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton} from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";

import { useNavigate, useLocation } from "react-router-dom";




const Enable2fa = NiceModal.create(
  ({ data }) => {
    const [state, setState] = useState({});
    const modal = useModal();


    const schema = yup.object().shape({
      cur_password: yup.string().required("Enter your current password"),
    });
    const schema1 = yup.object().shape({
      code: yup.string()
        .matches(/^[0-9]+$/, "Enter 6 digit authentication code generated by your authentication app")
        .required("Authentication code is required")
        .min(6, "Enter 6 digit authentication code generated by your authentication app")
        .max(6, "Enter 6 digit authentication code generated by your authentication app"),
    });
    var enableAuth = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#enableauthpass");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/z_twostep_auth",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            setState({ ...state, ...response.data })
          }
        })
        .catch(function (response) { });
    };

    var enableAuth1 = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#twostepform");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/z_twostep_auth",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            modal.resolve({ resolved: true });
            modal.hide();

          }
        })
        .catch(function (response) { });
    };
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const {
      register: register1,
      handleSubmit: handleSubmit1,
      formState: { errors: errors1 },
      reset: reset1,
    } = useForm({
      resolver: yupResolver(schema1),
    });
    const onSubmitHandler = async (data) => {
      enableAuth();
      //reset();
    };
    const onSubmitHandler1 = async (data) => {
      enableAuth1();
      //reset();
    };



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);

    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Enable Two-Factor Authentication</BootstrapModal.Title>
        </BootstrapModal.Header>

        {state.token ? (

          <form id="twostepform" onSubmit={handleSubmit1(onSubmitHandler1)} noValidate>
            <BootstrapModal.Body>
              <div className="text-center">
                <p><b>Scan QR Code</b></p>
                <img src={state.qr} className="pb-3 w-50 qrimg ratio ratio-1x1" />
                <p className="text-secondary">Scan the QR code using <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US" target="_blank">Google Authenticator</a>, <a href="https://www.microsoft.com/en-us/account/authenticator" target="_blank">Microsoft Authenticator</a> or <a href="https://authy.com/download/" target="_blank">Authy</a>. After you scan the QR code, you’ll receive a 6 digit confirmation code to enter below.</p>
              </div>
              <div className="alert alert-warning d-flex align-items-center" role="alert">

                <div>
                  If you having trouble using the QR code, select manual entry on your app, and enter your username and the code: <b>{state.token}</b> </div>
              </div>

              <UiInput
                label="Enter Authentication Code"
                type="text"
                required={true}
                name="code"
                message={errors1.code?.message}
                {...register1("code")}
              />

              <input type="hidden" value={state.token_id} name="token_id" />



            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Turn On" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>

        ) : (



          <form id="enableauthpass" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>
              <p className="text-secondary">
                Verify your password to continue setting up two-factor authentication.
              </p>
              <UiInput
              required={true}
                lcol="4"
                icol="8"
                label="Password"
                type="password"
                name="cur_password"
                message={errors.cur_password?.message}
                {...register("cur_password")}
              />



            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Continue" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        )}




      </BootstrapModal>
    );
  }
);

export default Enable2fa;
