import axios from "axios";
import { getStorage, setStorage, Logout } from "./helper";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

export const instance = axios.create({
  baseURL: "https://api.practive.in",
  //timeout: 1000,
});

instance.interceptors.request.use(
  function (config) {
    if (getStorage("token")) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + getStorage("token"),
      };
    }

    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    //let navigat = useNavigate();
    
    if (error.response.status == 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return await axios(originalRequest);
        } catch (err) {
          return await Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = getStorage("rtoken");
      
      if (getStorage("rtoken") == null || getStorage("rtoken") == "undefined") {
        //console.log(refreshToken)
        Logout();
      } else {
        return new Promise(function (resolve, reject) {
          axios
            .post(
              "https://api.practive.in/z_relogin",
              {},
              {
                headers: {
                  Authorization: "Bearer " + refreshToken,
                },
              }
            )
            .then(({ data }) => {
              window.localStorage.setItem("token", data.token);
              window.localStorage.setItem("rtoken", data.rtoken);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.token;
              originalRequest.headers["Authorization"] = "Bearer " + data.token;
              processQueue(null, data.token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
              Logout();
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
    }
 

    
    if (navigator.onLine == false) {
      toast("Looks like you're offline!", { type: "info", theme: "light" });
    }

    return Promise.reject(error);
  }
);
