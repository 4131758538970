import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { PageHeader, UiActioButton, UiTimeDisplay, UiActionDropDown, UiButton, PageError, BgLoader, TableZero } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; 
import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Helmet } from "react-helmet";
import { useQueryClient } from 'react-query';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import {rupees} from '../../helper'



export default function ViewUpdate() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});
  const [vloader, showVLoader] = useState(false);
  const [bg_loader, setBGLoader] = useState(false);
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/z_update_updateitm?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['updates'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }











  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/z_view_update?id=" + id)
      .then(function (response) {
        setBGLoader(false)

        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);



  const BasicDetail = (props) => {
    const { label, val, ...other } = props;
    return (
      <>
        {val &&
          <div className="row mb-3">
            <div className="col-lg-4 text-muted">
              {label}
            </div>
            <div className="col-lg-8" {...other}>

            </div>
          </div>
        }
      </>
    )
  }



  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }




  return (
    <>
      <Helmet>
        {state.item && (
          <title>
            {state.item.title} | Practive
          </title>
        )}

      </Helmet>
      <div>
        {state.item ? (
          <>

            <PageHeader title={state.item.title} parent="Updates" link="/updates" stacked={true}>

              <>
                <button className="btn btn-outline-primary ms-2" onClick={() => navigate('/update/' + id)}>Edit</button>
              </>


              <UiButton className="btn btn-outline-danger ms-2" loading={loader.delete} onClick={() => handleDelete(id)} title="Delete" />

            </PageHeader>





            {bg_loader == true ? <BgLoader /> : (
              <>

                <div className="row">
                  <div className="col-md-6">
                    <div className="card card-default mb-4">
                      <div className="card-body">
                        {state.item.photo && <img src={state.item.photo} className="img-fluid mb-2 rounded" />}
                        <BasicDetail label="Type" val={state.item.type}>{state.item.type}</BasicDetail>
                        <BasicDetail label="Date" val={state.item.date}>{state.item.date}</BasicDetail>
                        <BasicDetail label="Title" val={state.item.title}>{state.item.title}</BasicDetail>
                        <BasicDetail label="Description" val={state.item.description}><div dangerouslySetInnerHTML={{ __html: state.item.description }} /></BasicDetail>
                        <BasicDetail label="URL" val={state.item.url}>{state.item.url}</BasicDetail>
                        <BasicDetail label="CTA" val={state.item.cta}>{state.item.cta}</BasicDetail>

                        <div className="row text-center border-top pt-2 mt-2">
                          <div className="col">
                            Positive <span className="badge bg-success">{state.item.positive}</span>
                          </div>
                          <div className="col">
                            Negative <span className="badge bg-danger">{state.item.negative}</span>

                          </div>  
                          <div className="col"> 
                            Neutral <span className="badge bg-warning">{state.item.neutral}</span>
                            </div>
                        </div>


                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card card-default mb-4">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <div>
                          Feedbacks
                          </div>
                          <div>
                            
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        {state.feedbacks.length == 0 && <TableZero title="Feedbacks" />}
                        {state.feedbacks.map((val, i) => (
                          <div className="list1" key={i}>
                            <UiTimeDisplay time={val.created_on} />
                            <div className="mt-2 d-flex justify-content-between">
                              <div>
                                <span className="text-muted">Company:</span> <b>{val.company}</b>
                              </div>
                              <div>
                                <span className="text-muted">User:</span> <b>{val.name}</b>
                              </div>
                            </div>
                            <p className="mt-2 mb-2"><span className="text-muted">Remark</span><br></br>{val.msg}</p>
                        
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                </div>


              </>
            )}
          </>

        ) : <BgLoader />}


      </div>
    </>
  );
}
